import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = (props) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [userRole, setUserRole] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            try {
                const decodedToken = jwtDecode(token);
                const { exp, role } = decodedToken;

                if (Date.now() < exp * 1000) {
                    setIsAuthenticated(true);
                    setUserRole(role || []); // Set role from the token
                } else {
                    setIsAuthenticated(false);
                    setUserRole([]);
                }
            } catch (e) {
                console.error('Token is invalid:', e);
                setIsAuthenticated(false);
                setUserRole([]);
            }
        } else {
            setIsAuthenticated(false);
            setUserRole([]);
        }
    }, []);

    const login = (token) => {
        localStorage.setItem('token', token);
        try {
            const decodedToken = jwtDecode(token);
            console.log(decodedToken.role)
            setIsAuthenticated(true);
            setUserRole(decodedToken.role || []);
        } catch (e) {
            console.error('Error decoding token:', e);
            setIsAuthenticated(false);
            setUserRole([]);
        }
    };

    const logout = () => {
        localStorage.removeItem('token');
        setIsAuthenticated(false);
        setUserRole([]); // Clear role on logout
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated, userRole, login, logout }}>
            {props.children}
        </AuthContext.Provider>
    );
};
