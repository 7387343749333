import * as turf from '@turf/turf';
import * as L from 'leaflet'; // Import Leaflet library

export const turfPolygonToLPolygon = (turfPolygon, type) => {
    // Initialize arrays to store outer boundaries and inner holes
    const outerBoundaries = [];
    const innerHoles = [];

    // Extract coordinates from MultiPolygon geometry
    const coordinates = turfPolygon.geometry.coordinates;
    const id = turfPolygon.properties.id;
    const area = turf.area(turfPolygon).toFixed(2);

    // Iterate over each polygon in the MultiPolygon
    coordinates.forEach((polygon, index) => {
        // Extract outer ring coordinates
        if (index === 0) {
            const outerRingCoords = polygon.map(coords => L.latLng(coords[1], coords[0]));
            outerBoundaries.push(outerRingCoords);
        }
        // If there are inner rings (holes), add them to the innerHoles array
        else {
            const holes = polygon.map(coords => L.latLng(coords[1], coords[0]));
            innerHoles.push(holes);
        }
    });

    // Create a Leaflet polygon with holes
    const layer = L.polygon([outerBoundaries[0], ...innerHoles], {id, type, area});
    return layer;
};