// import axios from 'axios';

// // NODE_ENV = 'development'
// // NODE_ENV = 'production'

// // If in 'production' baseURL = "/atmss"
// // If in 'development' baseURL = "http://localhost:5000/atmss"

// const baseURL = process.env.NODE_ENV === 'production' ? "/atmss" : "http://localhost:5000/atmss";
// const token = localStorage.getItem('token');

// export default axios.create({
//     baseURL,
//     headers: {
//         'Authorization': `Bearer ${token}`
//     }
// });

import axios from 'axios';

// Determine the base URL based on environment
const baseURL = process.env.NODE_ENV === 'production' ? "/atmss" : "http://localhost:5000/atmss";

// Create an Axios instance
const api = axios.create({
    baseURL,
});

// // Add a request interceptor to attach the token to each request
// api.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

// // Add a response interceptor to handle token expiration or errors
// api.interceptors.response.use((response) => {
//     return response;
// }, (error) => {
//     if (error.response && error.response.status === 401) {
//         // Handle unauthorized access (e.g., redirect to login page)
//         // For example, you could use history.push('/login') if you're using React Router
//         localStorage.removeItem('token');
//         window.location.href = '/'; // Redirect to main entry point
//     }
//     return Promise.reject(error);
// });

export default api;

















////////////////QUICKFIX ABOVE

// // import axios from 'axios';

// // // NODE_ENV = 'development'
// // // NODE_ENV = 'production'

// // // If in 'production' baseURL = "/atmss"
// // // If in 'development' baseURL = "http://localhost:5000/atmss"

// // const baseURL = process.env.NODE_ENV === 'production' ? "/atmss" : "http://localhost:5000/atmss";
// // const token = localStorage.getItem('token');

// // export default axios.create({
// //     baseURL,
// //     headers: {
// //         'Authorization': `Bearer ${token}`
// //     }
// // });

// import axios from 'axios';

// // Determine the base URL based on environment
// const baseURL = process.env.NODE_ENV === 'production' ? "/atmss" : "http://localhost:5000/atmss";

// // Create an Axios instance
// const api = axios.create({
//     baseURL,
// });

// // Add a request interceptor to attach the token to each request
// api.interceptors.request.use((config) => {
//     const token = localStorage.getItem('token');
//     if (token) {
//         config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
// }, (error) => {
//     return Promise.reject(error);
// });

// // Add a response interceptor to handle token expiration or errors
// api.interceptors.response.use((response) => {
//     return response;
// }, (error) => {
//     if (error.response && error.response.status === 401) {
//         // Handle unauthorized access (e.g., redirect to login page)
//         // For example, you could use history.push('/login') if you're using React Router
//         localStorage.removeItem('token');
//         window.location.href = '/'; // Redirect to main entry point
//     }
//     return Promise.reject(error);
// });

// export default api;
