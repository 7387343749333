import { useContext } from 'react';
import { AuthContext } from '../context/AuthContext'; // Adjust the path as needed

const useRoles = (requiredRoles) => {
    const { userRole, isAuthenticated } = useContext(AuthContext);

    if (!isAuthenticated) return false;

    // Check if any of the required roles match the user's roles
    return requiredRoles.some(role => userRole.includes(role));
};

export default useRoles;
