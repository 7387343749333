/**
 * Checks if a point is inside a polygon.
 * @param {Array<number>} point - The coordinates of the point [lon, lat].
 * @param {GeoJSON} layer - The GeoJSON layer.
 * @returns {boolean} - True if the point is inside the polygon, false otherwise.
 */
export const isPointInPolygon = (point, polygonLayer) => {
    if (point) {
        const turf = require('@turf/turf');

        const { lat, lng } = point;
        const pointArray = [lng, lat]; // Convert the point from drawingLayer to Array
        const turfPoint = turf.point(pointArray); // Create a Turf.js point feature

        // Converting the first polygon to a Polygon
        // TODO change this if the postgis data type is Polygon instead of Multipolygon
        const polygonGeometry = polygonLayer.geometry.coordinates[0];
        const turfPolygon = turf.polygon(polygonGeometry);
     
        // Define your tolerance in meters (e.g., 1 meter)
        const tolerance = 0.10; // 10 cm
        // Create a buffer around the point with the specified tolerance
        const bufferedPolygon = turf.buffer(turfPolygon, tolerance, { units: 'meters' });

        return turf.booleanPointInPolygon(turfPoint, bufferedPolygon);
    }
    else {
        return false;
    }
};