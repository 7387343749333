import React, { useState, useEffect, useContext } from 'react';

import { Box, TextField, Select, MenuItem, InputLabel, FormControl, OutlinedInput, List, ListItem } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import { MapContext } from '../../../context/MapContext';

import ATMSSModal from '../common/ATMSSModal';
import UploadImage from '../uploader/UploadImage';

const SourceReference = ({ showModal, onClose }) => {
    const getTodayDate = () => {
        const today = new Date();
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const day = String(today.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const { postSource, setSourceIsPosted } = useContext(MapContext);

    const [error, setError] = useState(null);

    const [sourcePin, setSourcePin] = useState('');
    const [sourceType, setSourceType] = useState('');
    const [sourceDate, setSourceDate] = useState(getTodayDate);
    const [description, setDescription] = useState('');
    const [isCommitable, setIsCommitable] = useState(false);
    const [isCommitted, setIsCommitted] = useState(false);

    const modalContent = {
        singleButton: true,
        onCloseText: "Back",
        onAcceptText: "Save",
        title: "Reference Material Information",
    };

    const handleAccept = () => {
        const uploadedImage = localStorage.getItem('uploadedImage');

        const missingReq = [];
        if (!uploadedImage) missingReq.push('Upload the Reference File');
        if (!sourcePin) missingReq.push('Specify Reference Number');
        if (!sourceType) missingReq.push('Select Reference Type');

        if (missingReq.length !== 0) {
            setError(missingReq.join('\n'));
        } else {
            setError(''); // Reset error message
            setIsCommitable(true);
            onClose();
        }
    };

    // File-handling
    const commit = async () => {
        if (isCommitable && !isCommitted) {

            const formData = new FormData();
            formData.append('source_pin', sourcePin);
            formData.append('source_type', sourceType);
            formData.append('source_date', sourceDate);
            formData.append('description', description);

            // Retrieve the uploaded image from local storage
            const uploadedImage = localStorage.getItem('uploadedImage');

            if (uploadedImage) {
                // Convert the base64 image to a Blob
                const byteString = atob(uploadedImage.split(',')[1]);
                const mimeString = uploadedImage.split(',')[0].split(':')[1].split(';')[0];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([ab], { type: mimeString });
                formData.append('jpeg', blob, 'uploadedImage.jpeg');
            } else {
                alert('No image file found. Please upload an image.');
                return;
            }

            try {
                // Replace with your API endpoint
                const result = await postSource(formData);

                if (result) {
                    console.log(result);
                    setSourceIsPosted(true);
                    setIsCommitted(true);
                } else {
                    setSourceIsPosted(false);
                    alert('Failed to commit the Source Reference.');
                }
            } catch (error) {
                console.error('Error committing the Source Reference:', error);
                alert('An error occurred while committing the Source Reference.');
            }

            setIsCommitable(false);
        }
    };

    const renderErrorMessages = (errorString) => {
        return errorString.split('\n').map((line, index) => (
            <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <WarningIcon sx={{ marginRight: 1 }} />
                {line}
            </ListItem>
        ));
    };

    useEffect(() => {
        const storedData = JSON.parse(localStorage.getItem('sourceReferenceData'));
        if (storedData) {
            setSourcePin(storedData.sourcePin || '');
            setSourceType(storedData.sourceType || '');
            setSourceDate(storedData.sourceDate || getTodayDate);
            setDescription(storedData.description || '');
        }

        // Unmounting
        return () => {
            localStorage.removeItem('uploadedImage');
        };
    }, []);

    useEffect(() => {
        if (isCommitable) {
            commit();
        }
        // eslint-disable-next-line
    }, [isCommitable]);

    useEffect(() => {
        const missingReq = [];
        if (!sourcePin) missingReq.push('Specify Reference Number');
        if (!sourceType) missingReq.push('Select Reference Type');

        if (missingReq.length !== 0) {
            setError(missingReq.join('\n'));
        } else {
            setError(''); // Reset error message
            setIsCommitable(true);
        }

        const dataToSave = {
            sourcePin,
            sourceType,
            sourceDate,
            description,
        };
        localStorage.setItem('sourceReferenceData', JSON.stringify(dataToSave));

        //TODO need to implement a change in file
        // eslint-disable-next-line
    }, [sourcePin, sourceType, sourceDate, description]);

    return (
        <ATMSSModal
            show={showModal}
            onCloseText={modalContent.onCloseText}
            onAcceptText={modalContent.onAcceptText}
            onClose={onClose}
            onAccept={handleAccept}
            title={modalContent.title}
        >
            <Box display="flex" justifyContent="center" mb={2}>
                <UploadImage />
            </Box>

            {error && (
                <List>
                    {renderErrorMessages(error)}
                </List>
            )}

            <Box mb={2}>
                <FormControl fullWidth variant="outlined" size="small">
                    <TextField
                        label="Reference Number (e.g. Plan Number)"
                        value={sourcePin}
                        onChange={(event) => setSourcePin(event.target.value)}
                        variant="outlined"
                        size="small"
                    />
                </FormControl>
            </Box>

            <Box mb={2}>
                <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="sourceType-label">Reference Type</InputLabel>
                    <Select
                        labelId="sourceType-label"
                        id="sourceType"
                        value={sourceType}
                        onChange={(event) => setSourceType(event.target.value)}
                        label="Reference Type"
                        input={<OutlinedInput label="Reference Type" />}
                    >
                        <MenuItem value="" disabled>Select Source Type</MenuItem>
                        <MenuItem value="title">Original/Transfer Title</MenuItem>
                        <MenuItem value="deed">Deed of Sales</MenuItem>
                        <MenuItem value="tax_declaration">Tax Declaration</MenuItem>
                        <MenuItem value="survey_plan">Survey Plan</MenuItem>
                        <MenuItem value="other_documents">Other Documents</MenuItem>
                    </Select>
                </FormControl>
            </Box>

            <Box mb={2}>
                <FormControl fullWidth variant="outlined" size="small">
                    <TextField
                        label="Date Uploaded"
                        type="date"
                        value={sourceDate}
                        onChange={(event) => setSourceDate(event.target.value)}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        size="small"
                    />
                </FormControl>
            </Box>

            <Box mb={2}>
                <FormControl fullWidth variant="outlined" size="small">
                    <TextField
                        label="Description"
                        value={description}
                        onChange={(event) => setDescription(event.target.value)}
                        variant="outlined"
                        size="small"
                        multiline
                        rows={2}
                    />
                </FormControl>
            </Box>
        </ATMSSModal>
    );
};

export default SourceReference;
