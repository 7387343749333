import React, { useState, useEffect, useContext } from 'react';

import { Box, FormControl, TextField, List, ListItem } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';

import { SectionsContext } from '../../../context/SectionsContext';
import { ParcelsContext } from '../../../context/ParcelsContext';
import { TaxParcelsContext } from '../../../context/TaxParcelsContext';
import { MapContext } from '../../../context/MapContext';

import ATMSSModal from '../common/ATMSSModal';

const AttributeForm = ({ showModal, onClose }) => {
    const { section } = useContext(SectionsContext);
    const { parcel } = useContext(ParcelsContext);
    const { taxParcel, taxParcels, setAttributeData } = useContext(TaxParcelsContext);
    const { enableCreateTools, enableEditTools, drawnLayer, setAttributeIsPosted } = useContext(MapContext);

    const [tPNumber, setTPNumber] = useState('');
    const [geometry, setGeometry] = useState(null);
    const [declaredArea, setDeclaredArea] = useState(0);

    const [error, setError] = useState(null);

    const modalContent = {
        onCloseText: "Back",
        onAcceptText: "Ok",
        title: "Attribute Information"
    };

    const paddingTPNumber = (event) => {
        const { value } = event.target;
        const intValue = parseInt(value);
        const strValue = intValue.toString();
        const updatedValue = strValue.padStart(3, '0');
        setTPNumber(updatedValue.slice(-3));
    };

    const paddingArea = (event) => {
        const { value } = event.target;
        const intValue = parseInt(value);

        if (intValue < 0) {
            setDeclaredArea(0);
        } else {
            setDeclaredArea(intValue);
        }
    };

    const handleAccept = async () => {
        //this will only assemble the data
        if (enableCreateTools) {
            const missingReq = [];
            if (!tPNumber) missingReq.push('Specify a valid Parcel Number');
            if (!declaredArea) missingReq.push('Specify the area')

            const attributeData = {
                tax_parcel_number: tPNumber,
                parcel_id: parcel.parcel_id,
                declared_area: declaredArea,
                geometry
            };

            if (missingReq.length !== 0) {
                setError(missingReq.join('\n'));
            } else {
                setError(''); // Reset error message
                setAttributeData(attributeData);
                setAttributeIsPosted(true);
                onClose();
            }
        }
        // else if (enableEditTools) {
        //     const taxPartitions = [];
        //     if (partitionLayerGroup) {
        //         const partitions = Object.values(partitionLayerGroup.getLayers());
        //         partitions.forEach(partition => {
        //             const layer = partition.toGeoJSON().features[0];
        //             const geometry = layer.geometry;
        //             const tPNumber = layer.properties.tax_parcel_number;

        //             const taxParcelData = {
        //                 tax_parcel_number: tPNumber,

        //                 parcel_id: parcel.parcel_id,
        //                 source_id: sourceId,
        //                 geometry
        //             };

        //             taxPartitions.push(taxParcelData);
        //         });
        //     }

        //     try {
        //         const response = await deleteTaxParcel(taxParcel.tax_parcel_id);
        //         console.log(response);
        //     } catch (error) {
        //         console.log(`Unknown Error: ${error}`);
        //         setError(error);
        //     }

        //     const successMessage = [];
        //     for (const partition of taxPartitions) {
        //         try {
        //             const response = await postTaxParcel(partition);
        //             successMessage.push(response);
        //         } catch (error) {
        //             console.log(`Unknown Error: ${error}`);
        //             setError(error);
        //         }
        //     }

        //     if (successMessage.length !== 0) {
        //         alert(successMessage.join('\n'));
        //         setIsCommitted(true);
        //     }
        // }
    };

    const renderErrorMessages = (errorString) => {
        return errorString.split('\n').map((line, index) => (
            <ListItem key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                <WarningIcon sx={{ marginRight: 1 }} />
                {line}
            </ListItem>
        ));
    };

    useEffect(() => {
        if (drawnLayer) {
            setGeometry(drawnLayer.geometry);

            let tpNum = parcel.parcel_number;
            if (tPNumber === '') {
                if (taxParcels) {
                    for (const taxParcel of taxParcels) {
                        const tpNumOfTaxParcel = taxParcel.tax_parcel_number;
                        if (tpNumOfTaxParcel > tpNum) {
                            tpNum = tpNumOfTaxParcel;
                        }
                    }
                    setTPNumber(tpNum.toString().padStart(3, '0'));
                } else {
                    setTPNumber(tpNum);
                }
            }

            if (enableEditTools) {
                if (taxParcel) {
                    setDeclaredArea(taxParcel.declared_area);
                }
            }
        }
        // eslint-disable-next-line
    }, [drawnLayer]);

    useEffect(() => {
        console.log("clearing");
        return () => {
        };
    }, []);

    useEffect(() => {
        const missingReq = [];
        if (!tPNumber) missingReq.push('Specify a valid Parcel Number');
        if (!declaredArea) missingReq.push('Specify the area')

        const attributeData = {
            tax_parcel_number: tPNumber,
            parcel_id: parcel.parcel_id,
            declared_area: declaredArea,
            geometry
        };

        if (missingReq.length !== 0) {
            setError(missingReq.join('\n'));
        } else {
            setError(''); // Reset error message
            setAttributeData(attributeData);
            setAttributeIsPosted(true);
        }
        // eslint-disable-next-line
    }, [tPNumber, declaredArea]);

    return (
        <ATMSSModal
            show={showModal}
            onCloseText={modalContent.onCloseText}
            onAcceptText={modalContent.onAcceptText}
            onClose={onClose}
            onAccept={handleAccept}
            title={modalContent.title}
        >
            <Box mb={2}>
                {error && (
                    <List>
                        {renderErrorMessages(error)}
                    </List>
                )}
            </Box>

            <Box mb={2}>
                <FormControl fullWidth variant="outlined" size="small">
                    <TextField
                        label="Parcel Identification Number (PIN)"
                        value={`${section.section_pin}-${tPNumber}`}
                        variant="outlined"
                        size="small"
                        InputProps={{ readOnly: true }}
                    />
                </FormControl>
            </Box>

            <Box mb={2}>
                <FormControl fullWidth variant="outlined" size="small">
                    <TextField
                        label="Parcel Number"
                        value={tPNumber}
                        onChange={paddingTPNumber}
                        variant="outlined"
                        size="small"
                        type="number"
                        InputProps={{ readOnly: !enableCreateTools }}
                    />
                </FormControl>
            </Box>

            <Box mb={2}>
                <FormControl fullWidth variant="outlined" size="small">
                    <TextField
                        label="Declared Area"
                        value={declaredArea}
                        onChange={paddingArea}
                        variant="outlined"
                        size="small"
                        type="number"
                        step="10"
                        InputProps={{ readOnly: !enableCreateTools }}
                    />
                </FormControl>
            </Box>
        </ATMSSModal>
    );
};

export default AttributeForm;
