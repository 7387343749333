import React from 'react';
import { useNavigate } from 'react-router-dom'; 

import { Box, Typography, Container, Link, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useRoles from '../../../hooks/useRoles';

const DisclaimerPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();  // Initialize navigate function

    const handleBackToHomepage = () => {
        window.close();
    }

    // role-based access control (RBAC)
    const hasAccess = useRoles(['mapper']); // Specify the roles required
    if (!hasAccess) {
        navigate('/login');
        return null;
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Box
                sx={{
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 1,
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Disclaimer
                </Typography>

                <Typography variant="body1" paragraph>
                    The information provided by the Automated Tax Mapping and Sectioning Systems (ATMSS) on this website is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site.
                </Typography>

                <Typography variant="body1" paragraph>
                    UNDER NO CIRCUMSTANCE SHALL WE HAVE ANY LIABILITY TO YOU FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A RESULT OF THE USE OF THE SITE OR RELIANCE ON ANY INFORMATION PROVIDED ON THE SITE. YOUR USE OF THE SITE AND YOUR RELIANCE ON ANY INFORMATION ON THE SITE IS SOLELY AT YOUR OWN RISK.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    External Links Disclaimer
                </Typography>
                <Typography variant="body1" paragraph>
                    The Site may contain (or you may be sent through the Site) links to other websites or content belonging to or originating from third parties or links to websites and features in banners or other advertising. Such external links are not investigated, monitored, or checked for accuracy, adequacy, validity, reliability, availability or completeness by us.
                </Typography>
                <Typography variant="body1" paragraph>
                    WE DO NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR THE ACCURACY OR RELIABILITY OF ANY INFORMATION OFFERED BY THIRD-PARTY WEBSITES LINKED THROUGH THE SITE OR ANY WEBSITE LINKED IN ANY BANNER OR OTHER ADVERTISING. WE WILL NOT BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Professional Disclaimer
                </Typography>
                <Typography variant="body1" paragraph>
                    The Site cannot and does not contain [professional/medical/legal/financial] advice. The [professional/medical/legal/financial] information is provided for general informational and educational purposes only and is not a substitute for professional advice.
                </Typography>
                <Typography variant="body1" paragraph>
                    YOU SHOULD NOT ACT OR REFRAIN FROM ACTING ON THE BASIS OF ANY CONTENT ON THE SITE WITHOUT SEEKING THE APPROPRIATE [PROFESSIONAL/MEDICAL/LEGAL/FINANCIAL] ADVICE ON THE PARTICULAR FACTS AND CIRCUMSTANCES THAT APPLY TO YOU.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Limitation of Liability
                </Typography>
                <Typography variant="body1" paragraph>
                    In no event shall Pasig City, nor its officials, officers, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, arising from (i) your use of or inability to use the Site; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Site; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Site by any third party; (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via the Site.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Changes to This Disclaimer
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to update or change our Disclaimer at any time and you should check this Disclaimer periodically. Your continued use of the Service after we post any modifications to the Disclaimer on this page will constitute your acknowledgment of the modifications and your consent to abide by and be bound by the modified Disclaimer.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions about this Disclaimer, please contact us:
                </Typography>
                <Typography variant="body1">
                    Email: <Link href="mailto:pasig.atmss@gmail.com" color="inherit">pasig.atmss@gmail.com</Link>
                </Typography>
                <Typography variant="body1">
                    Address: National Engineering Center, University of the Philippines, UP Campus, Diliman
                    Quezon City Metro Manila 1101 Philippines
                </Typography>

                {/* OK Button to navigate back to homepage */}
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Button variant="contained" color="primary" onClick={handleBackToHomepage}>
                        OK
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default DisclaimerPage;
