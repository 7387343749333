import React, { useState, useContext } from 'react';

import '../../../styles.css';
import renderMessageWithLineBreaks from '../../../utils/renderMessageWithLineBreaks';
import { parseBBox } from '../../../utils/parseBBox';

import { TaxParcelsContext } from '../../../context/TaxParcelsContext';
import { MapContext } from '../../../context/MapContext';

import ATMSSModal from '../ATMSSModal';

const EditTools = () => {
    const { taxParcel } = useContext(TaxParcelsContext);
    const {
        map, setUserInitiatedChange, setDrawnLayer, setEnableEditTools,
        partitionEditMode, setPartitionEditMode
    } = useContext(MapContext);


    // Track which Tools is active
    const [modeType, setModeType] = useState(null);
    const [nextModeType, setNextModeType] = useState(null);

    const [isVisible, setIsVisible] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        singleButton: 'enabled',
        onCloseText: 'Close',
        onAcceptText: 'Accept',
        title: 'Default Title',
        message: 'Default message content.'
    });

    // Handler to confirm change in create mode
    const handleSelectToolType = (newModeType) => {
        setIsVisible(false);
        // Initial select will go to handlers
        if (!modeType) {
            if (newModeType === 'Partition') {
                handlePartitionEdit();
            }
            setModeType(newModeType);
        }
        // Switching to different mode for confirmation
        else {
            if (modeType !== newModeType) {
                setModalContent({
                    onCloseText: 'Cancel',
                    onAcceptText: 'Accept',
                    title: `Warning: Changing the Editing Mode to ${newModeType}`,
                    message: `Are you sure you want to change to this mode?
                    \nCurrent inputs may be loss from this action.`
                });
                setNextModeType(newModeType);
                setShowModal(true);
            }
            // Case when pressing the 'by Partitioning' button while in partitionEditMode
            if (modeType === 'Partition' && newModeType === 'Partition' && partitionEditMode) {
                setModalContent({
                    singleButton: 'enabled',
                    onAcceptText: 'OK',
                    title: 'Information: Editing a Tax Parcel by partitioning',
                    message: `Partitioning involves dividing into smaller segments or parcels.
                    \nPlease use the Edit Tools on the left side of the map.`
                });
                setShowModal(true);
            }
        }
    };

    const handleCancelTool = () => {
        if (modeType) {
            setModalContent({
                onCloseText: 'Back',
                onAcceptText: 'Confirm',
                title: 'Warning: Cancel Tax Parcel Editing',
                message: `Are you sure you want to cancel?
                \nCurrent inputs may be loss from this action.`
            });
        } else {
            setModalContent({
                onCloseText: 'Back',
                onAcceptText: 'Confirm',
                title: 'Warning: Cancel Tax Parcel Editing',
                message: 'Are you sure you want to cancel?'
            });
        }
        setNextModeType('Cancel');
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNextModeType(null);
    };

    const handleAcceptModal = () => {
        // Select appropriate accept handlers
        if (nextModeType === 'Partition') {
            handlePartitionEdit();
        }
        // if (nextModeType === 'TD') {
        //     handleTDCreate();
        // }
        // if (nextModeType === 'Map') {
        //     handleMapCreate();
        // }
        if (nextModeType === 'Cancel') {
            handleCancelEdit();
        }

        // Assign the current type and hide the modal
        setModeType(nextModeType);
        setShowModal(false);
        setNextModeType(null);
    };

    const handlePartitionEdit = () => {
        flyToTaxParcel();
        // Reset others
        setDrawnLayer(null);

        setModalContent({
            singleButton: 'enabled',
            onAcceptText: 'OK',
            title: 'Information: Editing a Tax Parcel by partitioning',
            message: `Partitioning involves dividing into smaller segments or parcels.
            \nPlease use the 'Cutting Line' tool on the left side of the map.`
        });
        setShowModal(true);
        setPartitionEditMode(true);
        setUserInitiatedChange(true);
    };

    const handleCancelEdit = () => {
        // Reset everything
        setEnableEditTools(false);
        setPartitionEditMode(false);
        setDrawnLayer(null);
    };

    const flyToTaxParcel = () => {
        if (taxParcel) {
            const { bounds } = parseBBox(JSON.stringify(taxParcel.bbox));

            // Fly and zoom to bbox
            map.flyToBounds(bounds, {
                animate: true,
                duration: 1 // in seconds
            });
        }
    };

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <div className="container mt-2 no-padding">
            <div>
                <li className="d-grid gap-2">
                    <button className="btn fw-semibold flex-shrink-0 align-items-center rounded border-0 hover-highlight" onClick={toggleVisibility}>
                        {isVisible ? 'Hide' : 'Show'} Information
                    </button>
                </li>
                {isVisible && (
                    <>
                        <li className="border-top my-2"></li>
                        <span className="fs-6">
                            "Tax Parcel Editing" will modify a Tax Parcel,
                            including changes in boundaries, attributes, or both.
                            <br />Choose the appropriate mode for your task.
                            <br /><br />Use the
                            <span className="fw-semibold"> Commit </span>
                            button to save changes when finished.
                        </span>
                    </>
                )}
            </div>
            <li className="border-top my-2"></li>
            <ul className="list-unstyled ps-0">
                <li className="fs-6 fw-semibold mb-2 d-flex align-items-center justify-content-between">
                    <span className="link-body-emphasis d-inline-flex rounded">Tax Parcel Editing</span>
                    <button
                        className="btn flex-shrink-0 border-0 hover-highlight"
                        onClick={() => handleCancelTool()}
                    >
                        <i className="bi bi-x-lg"></i>
                    </button>
                </li>
                <li className="d-grid gap-2">
                    <button
                        className={`btn text-start fw-semibold flex-shrink-0 align-items-center rounded border-0 hover-highlight${modeType === 'Partition' ? ' active' : ''}`}
                        onClick={() => handleSelectToolType('Partition')}
                    >
                        <i className="bi bi-vr"></i> by Partitioning
                    </button>
                </li>
                {/* <li className="d-grid gap-2">
                    <button
                        className={`btn text-start fw-semibold flex-shrink-0 align-items-center rounded border-0 hover-highlight${modeType === 'TD' ? ' active' : ''}`}
                        onClick={() => handleSelectToolType('TD')}
                    >
                        <i className="bi bi-card-text"></i> from TD
                    </button>
                </li>
                <li className="d-grid gap-2">
                    <button
                        className={`btn text-start fw-semibold flex-shrink-0 align-items-center rounded border-0 hover-highlight${modeType === 'Map' ? ' active' : ''}`}
                        onClick={() => handleSelectToolType('Map')}
                    >
                        <i className="bi bi-pin-map"></i> from Map
                    </button>
                </li> */}
                <li className="border-top my-2"></li>
                {/* Modal */}
                <div>
                    <ATMSSModal
                        show={showModal}
                        singleButton={modalContent.singleButton}
                        onCloseText={modalContent.onCloseText}
                        onAcceptText={modalContent.onAcceptText}
                        onClose={handleCloseModal}
                        onAccept={handleAcceptModal}
                        title={modalContent.title}
                    >
                        <p>
                            {renderMessageWithLineBreaks(modalContent.message)}
                        </p>
                    </ATMSSModal>
                </div>
            </ul>
        </div>
    );
};

export default EditTools;