import React, { useContext } from 'react';

import { Box, Typography, Link, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import MailOutlineIcon from '@mui/icons-material/MailOutline';
import FacebookIcon from '@mui/icons-material/Facebook'; // Facebook icon
import Brightness4Icon from '@mui/icons-material/Brightness4'; // Dark mode icon
import Brightness7Icon from '@mui/icons-material/Brightness7'; // Light mode icon

import { ThemeContext } from '../../../context/ThemeContext';

const ATMSSFooter = () => {
    const { isDarkMode, toggleTheme } = useContext(ThemeContext); // Access the theme context
    const theme = useTheme();

    const handleNavigateToAbout = () => {
        window.open('/about', '_blank');  // Navigate to AboutPage
    };

    return (
        <Box
            component="footer"
            sx={{
                width: '100%', // Ensure the footer spans the full width of the screen
                backgroundColor: '#062A67',
                color: theme.palette.primary.contrastText,
                py: 2,
                borderTop: `1px solid ${theme.palette.divider}`, // Use theme divider color
                px: 2, // Apply padding on the x-axis
                [theme.breakpoints.up('sm')]: {
                    px: 4, // Increase padding on larger screens
                },
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ flexWrap: 'wrap' }}
            >
                {/* Left-aligned About text as a button */}
                <Box>
                    <Typography
                        variant="h6"
                        onClick={handleNavigateToAbout}
                        sx={{
                            fontFamily: theme.typography.fontFamily,
                            cursor: 'pointer',
                            textDecoration: 'none',  // Remove underline
                            color: theme.palette.primary.contrastText, // Use theme color for text
                            '&:hover': {
                                textDecoration: 'underline',  // Underline on hover
                            },
                        }}
                    >
                        About
                    </Typography>
                </Box>

                {/* Centered Contact info and Links */}
                <Box
                    display="flex"
                    alignItems="center"
                    sx={{ flexWrap: 'wrap', gap: 2 }}
                >
                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        Contact us:
                        <IconButton component="a" href="mailto:pasig.atmss@gmail.com" color="inherit" sx={{ ml: 1 }}>
                            <MailOutlineIcon />
                        </IconButton>
                        <IconButton component="a" href="https://www.facebook.com/PasigPIO/" color="inherit" sx={{ ml: 1 }}>
                            <FacebookIcon />
                        </IconButton>
                    </Typography>

                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        © {new Date().getFullYear()} Swinnerton Staten Solutions, Inc. (SSSI)
                    </Typography>

                    <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                        <Link href="/privacy" color="inherit" target="_blank" rel="noopener noreferrer" sx={{ mr: 1 }}>Privacy Policy</Link>|
                        <Link href="/terms" color="inherit" target="_blank" rel="noopener noreferrer" sx={{ mx: 1 }}> Terms of Service</Link>|
                        <Link href="/disclaimer" color="inherit" target="_blank" rel="noopener noreferrer" sx={{ ml: 1 }}> Disclaimer</Link>
                    </Typography>

                    <Link href="https://pasigcity.gov.ph/" color="inherit" target="_blank" rel="noopener noreferrer">
                        Visit the Pasig City Website
                    </Link>
                </Box>

                {/* Theme toggle button with Tooltip */}
                <Box>
                    <Tooltip title={isDarkMode ? "Switch to Light Mode" : "Switch to Dark Mode"}>
                        <IconButton onClick={toggleTheme} color="inherit">
                            {isDarkMode ? <Brightness7Icon /> : <Brightness4Icon />}
                        </IconButton>
                    </Tooltip>
                </Box>
            </Box>
        </Box>
    );
};

export default ATMSSFooter;
