/**
 * LeafletStyles module provides a collection of styles for different layers in Leaflet maps.
 * @module LeafletStyles
 */

/**
 * Object containing styles for different layers in Leaflet maps.
 * @const {Object}
 */
const LeafletStyles = {
    /**
     * Style for district layer.
     * @type {Object}
     */
    districtLayer: {
      fillColor: '#99ece2',
      fillOpacity: 0.7,
      color: '#b30000',
      opacity: 1,
      dashArray: '10, 5',
      weight: 2,
    },
    /**
     * Style for barangay layer.
     * @type {Object}
     */
    barangayLayer: {
      fillColor: '#9ecae1',
      fillOpacity: 0.7,
      color: '#08519c',
      opacity: 1,
      dashArray: '5, 5, 2, 5',
      weight: 2,
    },
    /**
     * Style for section layer.
     * @type {Object}
     */
    sectionLayer: {
      fillColor: '#feedde',
      fillOpacity: 0.7,
      color: '#a50f15',
      opacity: 1,
      dashArray: '15, 5, 5, 5',
      weight: 2,
    },
    /**
     * Style for parcel layer.
     * @type {Object}
     */
    parcelLayer: {
      fillColor: '#dad383',
      fillOpacity: 0.7,
      color: '#00441b',
      opacity: 1,
      dashArray: '5, 3',
      weight: 2,
    },
    /**
     * Style for neighbor layer.
     * @type {Object}
     */
    neighborLayer: {
      fillColor: '#a4e196',
      fillOpacity: 0.7,
      color: '#756bb1',
      opacity: 1,
      dashArray: '8, 5',
      weight: 2,
    },
    /**
     * Style for tax parcel layer.
     * @type {Object}
     */
    taxParcelLayer: {
      fillColor: '#bd7c68',
      fillOpacity: 0.7,
      color: '#990000',
      opacity: 1,
      dashArray: '12, 6',
      weight: 2,
    },
    /**
     * Style for highlight layer.
     * @type {Object}
     */
    highlightLayer: {
      fillColor: '#003067',
      fillOpacity: 1,
      color: '#fce6ac',
      opacity: 1,
      weight: 3,
    },
    /**
     * Style for no style layer.
     * @type {Object}
     */
    noStyleLayer: {
      fillColor: '0',
      fillOpacity: 0,
      color: '#ffeda0',
      opacity: 1,
      dashArray: '2',
      weight: 3,
    },
    /**
     * Style for drawing layer.
     * @type {Object}
     */
    drawingLayer: {
      fillColor: '#3388ff',
      fillOpacity: 0.2,
      lineCap: 'round',
      lineJoin: 'round',
      color: '#3388ff',
      opacity: 1,
      weight: 3
    },
    /**
     * Style for partition layer.
     * @type {Object}
     */
    partitionLayer: {
      fillColor: '#55ffff',
      fillOpacity: 0.2,
      lineCap: 'round',
      lineJoin: 'round',
      color: '#55ffff',
      opacity: 1,
      weight: 3
    }
  };
  
  export default LeafletStyles;
  