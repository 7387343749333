/**
 * digitizePolygon - Function to digitize a polygon from given LatLng coordinates
 * 
 * @param {Array<Array<number>>} latLngs - Array of arrays containing latitude and longitude coordinates
 * @param {L.Map} map - Leaflet map object
 * @returns {void}
 */
export const digitizePolygon = (latLngs, map) => {
    // Start the drawing mode for polygons
    map.pm.enableDraw('Polygon', {
        //snappable: true, // Enable snapping if needed
        //snapDistance: 20, // Set the snapping distance if snappable is enabled
        allowSelfIntersection: false // Set other options as needed
    });

    // Simulate a click event at each LatLng coordinate
    latLngs.forEach((latLng, index) => {
        setTimeout(() => {
            map.fireEvent('click', {
                latlng: latLng,
                layerPoint: map.latLngToLayerPoint(latLng),
                containerPoint: map.latLngToContainerPoint(latLng),
                originalEvent: new MouseEvent('click', {
                    bubbles: true,
                    cancelable: true,
                    which: 1
                })
            });
        }, index * 1); // Adjust the delay between clicks if needed
    });

    // End the drawing mode
    setTimeout(() => {
        map.pm.disableDraw();
    }, latLngs.length * 1 + 10); // Set a timeout to disable drawing mode after all clicks
};
