import React, { useState, useContext, useEffect } from 'react';
import { TextField, MenuItem, Box, InputAdornment, Tooltip } from '@mui/material';
import { DistrictsContext } from '../../../context/DistrictsContext';
import { BarangaysContext } from '../../../context/BarangaysContext';
import { SectionsContext } from '../../../context/SectionsContext';
import { ParcelsContext } from '../../../context/ParcelsContext';
import IconComponent from '../common/IconComponent';

const MainFilter = () => {
    const [districtId, setDistrictId] = useState('default');
    const [barangayId, setBarangayId] = useState('default');
    const [sectionId, setSectionId] = useState('default');
    const [parcelId, setParcelId] = useState('default');

    const { districts, fetchDistrictsFromServer, fetchDistrict } = useContext(DistrictsContext);
    const { barangays, fetchBarangaysFromDistrict, fetchBarangay } = useContext(BarangaysContext);
    const { sections, fetchSectionsFromBarangay, fetchSection } = useContext(SectionsContext);
    const { parcels, fetchParcelsFromSection, fetchParcel } = useContext(ParcelsContext);

    const handleDistrictSelectChange = (event) => {
        const districtId = event.target.value;
        setDistrictId(districtId);
        setBarangayId('default');
        setSectionId('default');
        setParcelId('default');
    };

    const handleBarangaySelectChange = (event) => {
        const barangayId = event.target.value;
        setBarangayId(barangayId);
        setSectionId('default');
        setParcelId('default');
    };

    const handleSectionSelectChange = (event) => {
        const sectionId = event.target.value;
        setSectionId(sectionId);
        setParcelId('default');
    };

    const handleParcelSelectChange = (event) => {
        const parcelId = event.target.value;
        setParcelId(parcelId);
    };

    useEffect(() => {
        fetchDistrictsFromServer();

        // Remove right-click "contextmenu" behavior
        const handleContextMenu = (event) => event.preventDefault();
        document.addEventListener('contextmenu', handleContextMenu);

        return () => {
            document.removeEventListener('contextmenu', handleContextMenu);
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        fetchDistrict(districtId);
        fetchBarangaysFromDistrict(districtId);
        // eslint-disable-next-line
    }, [districtId]);

    useEffect(() => {
        fetchBarangay(barangayId);
        fetchSectionsFromBarangay(barangayId);
        // eslint-disable-next-line
    }, [barangayId]);

    useEffect(() => {
        fetchSection(sectionId);
        fetchParcelsFromSection(sectionId);
        // eslint-disable-next-line
    }, [sectionId]);

    useEffect(() => {
        fetchParcel(parcelId);
        // eslint-disable-next-line
    }, [parcelId]);

    return (
        <Box sx={{ width: '100%', p: 0, mb: 2 }}>
            <Box
                component="form"
                display="flex"
                flexDirection="row"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="space-between"
                sx={{ gap: 2 }}
            >
                <Tooltip title="Starting here select a District to filter by" arrow placement="top">
                    <TextField
                        id="district-select"
                        select
                        label="Select District"
                        value={districtId}
                        onChange={handleDistrictSelectChange}
                        sx={{ flex: 1, minWidth: '150px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconComponent iconType="district" />
                                </InputAdornment>
                            ),
                        }}
                    >
                        <MenuItem value="default" disabled>
                            Select District:
                        </MenuItem>
                        {districts && districts.map((district) => (
                            <MenuItem key={district.district_id} value={district.district_id}>
                                ({district.district_number}) {district.district_name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip>

                <Tooltip title="You need to select first the District to select a Barangay to filter by" arrow placement="top">
                    <TextField
                        id="barangay-select"
                        select
                        label="Select Barangay"
                        value={barangayId}
                        onChange={handleBarangaySelectChange}
                        sx={{ flex: 1, minWidth: '150px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconComponent iconType="barangay" />
                                </InputAdornment>
                            ),
                        }}
                    >
                        <MenuItem value="default" disabled>
                            Select Barangay:
                        </MenuItem>
                        {barangays && barangays.map((barangay) => (
                            <MenuItem key={barangay.barangay_id} value={barangay.barangay_id}>
                                ({barangay.barangay_number}) {barangay.barangay_name}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip>

                <Tooltip title="You need to select first the Barangay to select a Section to filter by" arrow placement="top">
                    <TextField
                        id="section-select"
                        select
                        label="Select Section"
                        value={sectionId}
                        onChange={handleSectionSelectChange}
                        sx={{ flex: 1, minWidth: '150px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconComponent iconType="section" />
                                </InputAdornment>
                            ),
                        }}
                    >
                        <MenuItem value="default" disabled>
                            Select Section:
                        </MenuItem>
                        {sections && sections.map((section) => (
                            <MenuItem key={section.section_id} value={section.section_id}>
                                ({section.section_number}) {section.section_pin}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip>

                <Tooltip title="You need to select first the Section to select a Parcel to filter by" arrow placement="top">
                    <TextField
                        id="parcel-select"
                        select
                        label="Select Parcel"
                        value={parcelId}
                        onChange={handleParcelSelectChange}
                        sx={{ flex: 1, minWidth: '150px' }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconComponent iconType="parcel" />
                                </InputAdornment>
                            ),
                        }}
                    >
                        <MenuItem value="default" disabled>
                            Select Parcel:
                        </MenuItem>
                        {parcels && parcels.map((parcel) => (
                            <MenuItem key={parcel.parcel_id} value={parcel.parcel_id}>
                                ({parcel.parcel_number}) {parcel.parcel_pin}
                            </MenuItem>
                        ))}
                    </TextField>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default MainFilter;
