import React, { useState, createContext } from 'react';
import DataFinder from '../apis/DataFinder';

// context for managing Districts
export const MapContext = createContext();
export const MapContextProvider = (props) => {
    const [map, setMap] = useState(null);
    const [mapMessage, setMapMessage] = useState(null);
    const [userInitiatedChange, setUserInitiatedChange] = useState(false);
    const [isCommitted, setIsCommitted] = useState(false);
    const [infoControl, setInfoControl] = useState(null);
    const [drawnLayer, setDrawnLayer] = useState(null);
    const [enableCreateTools, setEnableCreateTools] = useState(false);
    const [parcelCreateMode, setParcelCreateMode] = useState(false);
    const [tdCreateMode, setTdCreateMode] = useState(false);
    const [mapCreateMode, setMapCreateMode] = useState(false);
    const [enableEditTools, setEnableEditTools] = useState(false);
    const [partitionEditMode, setPartitionEditMode] = useState(false);
    const [isNewlyCreated, setIsNewlyCreated] = useState(false);
    const [isPartitioned, setIsPartitioned] = useState(false);
    const [showPartitions, setShowPartitions] = useState(false);
    const [partitionLayerGroup, setPartitionLayerGroup] = useState(null);
    const [person, setPerson] = useState(null);
    const [source, setSource] = useState(null);
    const [isUploaded, setIsUploaded] = useState(false);
    const [sourceIsPosted, setSourceIsPosted] = useState(false);
    const [attributeIsPosted, setAttributeIsPosted] = useState(false);

    // Fetch Person
    const fetchPerson = async (personId) => {
        try {
            setPerson(null);
            if (personId !== 'default' && personId !== undefined) { // Check if personId is not 'default' and 'undefined'
                // Make API call to fetch Person by personId
                const response = await DataFinder.get(`/persons/${personId}`);
                setPerson(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch Person.', error);
            setPerson(null);
        }
    };

    // Fetch Source
    const fetchSource = async (sourceId) => {
        try {
            setSource(null);
            if (sourceId !== 'default' && sourceId !== undefined) { // Check if sourceId is not 'default' and 'undefined'
                // Make API call to fetch Source by sourceId
                const response = await DataFinder.get(`/sources/${sourceId}`);
                setSource(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch Source.', error);
            setSource(null);
        }
    };

    // Post Person
    const postPerson = async (personData) => {
        try {
            const response = await DataFinder.post('/persons', personData);

            if (response.data){
                const {person_id} = response.data;
                await fetchPerson(person_id);
                
                console.log('Successfully created a person');
                return ('success');
            } 
        } catch (error) {
            console.log(`Error creating new person: ${error}`);
            setPerson(null);
        }
    }

    // Post source
    const postSource = async (sourceData) => {
        try {
            const response = await DataFinder.post('/sources', sourceData, {
                headers: {
                    'Content-Type': 'multipart/form-data' // Important for FormData
                }
            });

            if (response.data){
                const {source_id} = response.data;
                await fetchSource(source_id);
                
                console.log('Successfully created a source');
                return ('success');
            } 
        } catch (error) {
            console.log(`Error creating new source: ${error}`);
            setPerson(null);
        }
    }

    return (
        <MapContext.Provider value={{
            map, mapMessage, userInitiatedChange, isCommitted, infoControl, drawnLayer,  
            enableCreateTools, parcelCreateMode, tdCreateMode, mapCreateMode,
            enableEditTools, partitionEditMode, isNewlyCreated, isPartitioned, showPartitions, partitionLayerGroup,
            person, source, isUploaded, sourceIsPosted, attributeIsPosted,
            setMap, setMapMessage, setUserInitiatedChange, setIsCommitted, setInfoControl, setDrawnLayer,
            setEnableCreateTools, setParcelCreateMode, setTdCreateMode, setMapCreateMode,
            setEnableEditTools, setPartitionEditMode, setIsNewlyCreated, setIsPartitioned, setShowPartitions, setPartitionLayerGroup,
            setPerson, fetchPerson, postPerson, setSource, fetchSource, postSource, setIsUploaded, setSourceIsPosted, setAttributeIsPosted
        }}>
            {props.children}
        </MapContext.Provider>
    )
}