import React, { useRef } from 'react';
import Draggable from 'react-draggable';

const ATMSSModal = ({ show, singleButton, onCloseText, onAcceptText, onClose, onAccept, title, children }) => {
    const nodeRef = useRef(null);

    return (
        <Draggable nodeRef={nodeRef}>
            <div ref={nodeRef} className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? 'block' : 'none' }} tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fs-5">{title}</h5>
                            <button type="button" className="btn-close" onClick={onClose}></button>
                        </div>
                        <div className="modal-body">
                            {children}
                        </div>
                        <div className="modal-footer">
                            {singleButton ? (
                                <button type="button" className="btn btn-primary" onClick={onClose}>
                                    {onAcceptText}
                                </button>
                            ) : (
                                <>
                                    <button type="button" className="btn btn-secondary" onClick={onClose}>
                                        {onCloseText}
                                    </button>
                                    <button type="button" className="btn btn-primary" onClick={onAccept}>
                                        {onAcceptText}
                                    </button>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Draggable>
    );
};

export default ATMSSModal;
