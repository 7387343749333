// context/DataContextProvider.js
import React from 'react';
import { DistrictsContextProvider } from './DistrictsContext';
import { BarangaysContextProvider } from './BarangaysContext';
import { SectionsContextProvider } from './SectionsContext';
import { ParcelsContextProvider } from './ParcelsContext';
import { TaxParcelsContextProvider } from './TaxParcelsContext';

export const SpatialDataContextProvider = ({ children }) => {
    return (
        <DistrictsContextProvider>
            <BarangaysContextProvider>
                <SectionsContextProvider>
                    <ParcelsContextProvider>
                        <TaxParcelsContextProvider>
                                {children}
                        </TaxParcelsContextProvider>
                    </ParcelsContextProvider>
                </SectionsContextProvider>
            </BarangaysContextProvider>
        </DistrictsContextProvider>
    );
};
