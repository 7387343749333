import React, { useState, useContext } from 'react';
import { Box, Button, Typography, CircularProgress, Card, CardMedia } from '@mui/material';
import { PhotoCamera } from '@mui/icons-material';

import { MapContext } from '../../../context/MapContext';

const UploadImage = () => {
    const [image, setImage] = useState(null); // To hold the image data
    const [loading, setLoading] = useState(false); // To show loading state

    const { setIsUploaded } = useContext(MapContext);

    // Function to handle file upload
    const handleFileChange = (event) => {
        //reset
        setIsUploaded(false);

        const file = event.target.files[0];
        if (file && file.type === 'image/jpeg') {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLoading(true);
                // Save to local storage
                localStorage.setItem('uploadedImage', reader.result);
                setImage(reader.result);
                setLoading(false);
                setIsUploaded(true);
            };
            reader.readAsDataURL(file); // Convert image to Base64 string
        } else {
            alert('Please upload a JPEG image.');
        }
    };

    // Load image from local storage on component mount
    React.useEffect(() => {
        const savedImage = localStorage.getItem('uploadedImage');
        if (savedImage) {
            setImage(savedImage);
        }
    }, []);

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                mt: 4,
                p: 2,
                border: '1px solid #ddd',
                borderRadius: '8px',
                width: '500px',
            }}
        >
            <Typography variant="h7" gutterBottom>
                Upload a scan or photo of the Reference Lot Plan
            </Typography>

            <Button
                variant="contained"
                component="label"
                startIcon={<PhotoCamera />}
                sx={{ mb: 2 }}
            >
                Upload
                <input
                    type="file"
                    accept="image/jpeg"
                    hidden
                    onChange={handleFileChange}
                />
            </Button>

            {loading && <CircularProgress />}

            {image && !loading && (
                <Card sx={{ maxWidth: 500 }}>
                    <CardMedia
                        component="img"
                        height="200"
                        image={image}
                        alt="Uploaded Image"
                    />
                </Card>
            )}
        </Box>
    );
};

export default UploadImage;
