import React, { useState, createContext } from 'react';
import BarangayFinder from '../apis/DataFinder';

// context for managing Barangays
export const BarangaysContext = createContext();
export const BarangaysContextProvider = (props) => {
    const [barangays, setBarangays] = useState([]);
    const [barangay, setBarangay] = useState(null);

    // Fetch Barangays from District
    const fetchBarangaysFromDistrict = async (districtId) => {
        try {
            // Reset
            setBarangays([]);
            if (districtId !== 'default' && districtId !== undefined) { // Check if districtId is not 'default' and 'undefined'
                // Make API call to fetch Barangays from District with districtID
                const response = await BarangayFinder.get(`/districts/${districtId}/barangays`);
                setBarangays(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch all Barangays from District.', error);
            setBarangays([]);
        }
    };

    // Fetch Barangay
    const fetchBarangay = async (barangayId) => {
        try {
            setBarangay(null);
            if (barangayId !== 'default' && barangayId !== undefined) { // Check if barangayId is not 'default' and 'undefined'
                // Make API call to fetch Barangay by barangayId
                const response = await BarangayFinder.get(`/barangays/${barangayId}`);
                setBarangay(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch Barangay.', error);
            setBarangay(null);
        }
    };

    return (
        <BarangaysContext.Provider value={{
            barangays, barangay,
            setBarangays, setBarangay, fetchBarangaysFromDistrict, fetchBarangay
        }}>
            {props.children}
        </BarangaysContext.Provider>
    )
}