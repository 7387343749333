import React from 'react';

import { Box, Grid, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ATMSSHeader from '../../components/material_ui/common/ATMSSHeader';
import ATMSSFooter from '../../components/material_ui/common/ATMSSFooter';
import MainFilter from '../../components/material_ui/atmss_main/MainFilter';
import MainInfo from '../../components/material_ui/atmss_main/MainInfo';
import MainMapViewer from '../../components/material_ui/atmss_main/MainMapViewer';

// import AccessDeniedPage from './misc/AccessDeniedPage';
// import useRoles from '../../hooks/useRoles';

const ATMSSMainPage = () => {
    const theme = useTheme();

    // // role-based access control (RBAC)
    // const hasAccess = useRoles(['mapper']); // Specify the roles required
    // if (!hasAccess) {
    //     return <AccessDeniedPage />;
    // }

    return (
        <Box sx={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: '0 1 auto' }}>
                <ATMSSHeader />
            </Box>
            <Box sx={{ flex: '1 1 auto', display: 'flex', p: 2 }}>
                <Grid container spacing={2} >
                    <Grid item xs={12} md={10}>
                        <Paper elevation={3} sx={{ height: '100%', p: 2, backgroundColor: theme.palette.background.paper }}>
                            <Grid container spacing={0} sx={{ flex: '1 1 auto', height: '100%' }}>
                                <Grid item xs={12} >
                                    <MainFilter />
                                </Grid>
                                <Grid item xs={12} >
                                    <MainMapViewer />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Paper elevation={3} sx={{ height: '100%', p: 2, backgroundColor: theme.palette.background.paper }}>
                            <MainInfo />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            {/* <Box sx={{ flex: '1 1 auto', display: 'flex', p: 2 }}>
                <Grid container spacing={2} sx={{ height: '100%' }}>
                    <Grid item xs={12} md={10} sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                        <Paper elevation={3} sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', p: 2, backgroundColor: theme.palette.background.paper }}>
                            <Grid container spacing={0} sx={{ flex: '1 1 auto', height: '100%' }}>
                                <Grid item xs={12}>
                                    <MainFilter />
                                </Grid>
                                <Grid item xs={12} sx={{ flex: '1 1 auto', display: 'flex' }}>
                                    <MainMapViewer />
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Paper elevation={3} sx={{ height: '100%', p: 2, backgroundColor: theme.palette.background.paper }}>
                            <MainInfo />
                        </Paper>
                    </Grid>
                </Grid>
            </Box> */}
            <Box sx={{ flex: '0 1 auto' }}>
                <ATMSSFooter />
            </Box>
        </Box>
    );
}

export default ATMSSMainPage;
