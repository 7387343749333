import React from 'react';
import { useNavigate } from 'react-router-dom'; 

import { Box, Typography, Container, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useRoles from '../../../hooks/useRoles';

const AboutPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();  // Initialize navigate function

    const handleBackToHomepage = () => {
        // navigate('/');  // Navigate to homepage
        window.close();
    };

    // role-based access control (RBAC)
    const hasAccess = useRoles(['mapper']); // Specify the roles required
    if (!hasAccess) {
        navigate('/login');
        return null;
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Box
                sx={{
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 1,
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    About Us
                </Typography>

                <Typography variant="h5" gutterBottom>
                    The Digitally Automated Land Information Management System (DALIMS) Project
                </Typography>
                <Typography variant="body1" paragraph>
                    The Digitally Automated Land Information Management System (DALIMS) Project, a sub-program of the Comprehensive Land Information and Automation (CLIMA) Program of the City Government of Pasig, aims to establish advanced technology-based tools for the City Planning and Development Office (CPDO), the Office of the Building Official (OBO), and other vital departments of the LGU. This is achieved through the design, development, and installation of comprehensive and integrated Geographic Information Systems (GIS) and GIS-based Automation Solutions.
                </Typography>
                <Typography variant="body1" paragraph>
                    This computerization project is geared towards significantly improving and enhancing the regulatory functions of the Pasig City Government. It also aims to boost the city's revenue-generating capacity through more accurately directed, map-guided, and technically-informed planning and zoning enforcement activities, as well as for the conduct of tax campaigns, field collection efforts, and property tagging. Furthermore, the project aims to align the transaction processes of the OBO and CPDO Departments with Republic Act 11032, or the Anti-Red Tape Act (ARTA), through the deployment of the envisioned automation programs.
                </Typography>
                <Typography variant="body1" paragraph>
                    Swinnerton Staten Solutions, Inc. is the partner Systems Developer of the Pasig City Government for the implementation of the DALIMS/CLIMA I Project.
                </Typography>

                <Typography variant="h5" gutterBottom>
                    Pasig Automated Tax Map Sectioning Systems (Pasig ATMSS)
                </Typography>
                <Typography variant="body1" paragraph>
                    The Pasig Automated Tax Map Sectioning Systems (Pasig ATMSS) is a computer module designed to effectively utilize digital real estate data within a geographical information system (GIS) operation. It incorporates digital mapping tools that streamline several basic functionalities performed in traditional paper-based tax mapping. These functionalities include storing, editing, and updating both locational and attribute values related to tax maps.
                </Typography>
                <Typography variant="body1" paragraph>
                    With ATMSS, handling and visualizing real estate and tax maps through an interactive and informative user interface (UI) benefits both operational personnel and their clients. This system provides a competitive advantage for Pasig, positioning it as a pioneer in modern Land Administration Systems (LAS).
                </Typography>

                {/* OK Button to navigate back to homepage */}
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Button variant="contained" color="primary" onClick={handleBackToHomepage}>
                        OK
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default AboutPage;
