import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Container, Link, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useRoles from '../../../hooks/useRoles';

const PrivacyPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();  // Initialize navigate function

    const handleBackToHomepage = () => {
        window.close();
    };

    // role-based access control (RBAC)
    const hasAccess = useRoles(['mapper']); // Specify the roles required
    if (!hasAccess) {
        navigate('/login');
        return null;
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Box
                sx={{
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 1,
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Privacy Policy
                </Typography>

                <Typography variant="body1" paragraph>
                    At the Automated Tax Mapping and Sectioning Systems (ATMSS), we are committed to protecting your privacy. This Privacy Policy explains how we collect, use, and share your personal information when you visit our website or use our services.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    1. Information We Collect
                </Typography>
                <Typography variant="body1" paragraph>
                    We collect personal information that you provide to us directly, such as when you register for an account, contact us, or use our services. This may include your name, email address, phone number, and any other information you choose to provide.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    2. How We Use Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We use your personal information to provide and improve our services, communicate with you, and respond to your inquiries. We may also use your information to send you updates and promotional materials, but only if you have consented to receive them.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    3. Sharing Your Information
                </Typography>
                <Typography variant="body1" paragraph>
                    We do not share your personal information with third parties except as required by law or with your consent. We may share your information with our service providers who assist us in operating our website and services, but they are bound by confidentiality agreements.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    4. Security
                </Typography>
                <Typography variant="body1" paragraph>
                    We take reasonable measures to protect your personal information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, and we cannot guarantee absolute security.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    5. Your Choices
                </Typography>
                <Typography variant="body1" paragraph>
                    You have the right to access, correct, or delete your personal information. If you wish to exercise these rights or have any questions about our privacy practices, please contact us using the information provided below.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    6. Changes to This Policy
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our website or services after any changes constitutes your acceptance of the new policy.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    7. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about this Privacy Policy, please contact us at:
                </Typography>
                <Typography variant="body1">
                    Email: <Link href="mailto:pasig.atmss@gmail.com" color="inherit">pasig.atmss@gmail.com</Link>
                </Typography>
                <Typography variant="body1">
                    Address: National Engineering Center, University of the Philippines, UP Campus, Diliman
                    Quezon City Metro manila 1101 Philippines
                </Typography>

                {/* OK Button to navigate back to homepage */}
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Button variant="contained" color="primary" onClick={handleBackToHomepage}>
                        OK
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default PrivacyPage;
