import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Typography, Container, Link, Button } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import useRoles from '../../../hooks/useRoles';

const TermsPage = () => {
    const theme = useTheme();
    const navigate = useNavigate();  // Initialize navigate function

    const handleBackToHomepage = () => {
        window.close();
    };

    // role-based access control (RBAC)
    const hasAccess = useRoles(['mapper']); // Specify the roles required
    if (!hasAccess) {
        navigate('/login');
        return null;
    }

    return (
        <Container maxWidth="md" sx={{ mt: 4, mb: 4 }}>
            <Box
                sx={{
                    p: 3,
                    borderRadius: 2,
                    boxShadow: 1,
                    backgroundColor: theme.palette.background.paper,
                }}
            >
                <Typography variant="h4" gutterBottom>
                    Terms of Service
                </Typography>

                <Typography variant="body1" paragraph>
                    Welcome to the Automated Tax Mapping and Sectioning Systems (ATMSS). These Terms of Service ("Terms") govern your access to and use of our website and services. By using our website or services, you agree to comply with these Terms.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    1. Acceptance of Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    By accessing or using our website and services, you agree to these Terms and our Privacy Policy. If you do not agree to these Terms, you should not use our website or services.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    2. Use of Our Services
                </Typography>
                <Typography variant="body1" paragraph>
                    You agree to use our website and services only for lawful purposes and in accordance with these Terms. You must not engage in any activity that could damage, disable, overburden, or impair our services or interfere with any other party's use of our services.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    3. Intellectual Property
                </Typography>
                <Typography variant="body1" paragraph>
                    All content and materials on our website, including text, graphics, logos, and software, are the property of Pasig City or its licensors and are protected by copyright, trademark, and other intellectual property laws. You may not use, reproduce, or distribute any content from our website without our prior written permission.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    4. User Accounts
                </Typography>
                <Typography variant="body1" paragraph>
                    If you create an account on our website, you are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You must notify us immediately of any unauthorized use of your account or any other breach of security.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    5. Limitation of Liability
                </Typography>
                <Typography variant="body1" paragraph>
                    To the fullest extent permitted by law, Pasig City shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of our website or services. Our total liability for any claim related to our services shall not exceed the amount you paid for the service giving rise to the claim.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    6. Termination
                </Typography>
                <Typography variant="body1" paragraph>
                    We reserve the right to terminate or suspend your access to our website and services at our sole discretion, without notice, for any reason, including if you breach these Terms.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    7. Changes to These Terms
                </Typography>
                <Typography variant="body1" paragraph>
                    We may update these Terms from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of our website or services after any changes constitutes your acceptance of the new Terms.
                </Typography>

                <Typography variant="h6" gutterBottom>
                    8. Contact Us
                </Typography>
                <Typography variant="body1" paragraph>
                    If you have any questions or concerns about these Terms, please contact us at:
                </Typography>
                <Typography variant="body1">
                    Email: <Link href="mailto:pasig.atmss@gmail.com" color="inherit">pasig.atmss@gmail.com</Link>
                </Typography>
                <Typography variant="body1">
                    Address: National Engineering Center, University of the Philippines, UP Campus, Diliman
                    Quezon City Metro manila 1101 Philippines
                </Typography>

                {/* OK Button to navigate back to homepage */}
                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Button variant="contained" color="primary" onClick={handleBackToHomepage}>
                        Accept
                    </Button>
                </Box>
            </Box>
        </Container>
    );
};

export default TermsPage;
