import React, { useContext, useEffect, useRef } from 'react';

import { Card, CardContent } from '@mui/material';

import L from 'leaflet'; // Import Leaflet library
import { parseBBox } from '../../../utils/parseBBox';

// Import Contexts
import { DistrictsContext } from '../../../context/DistrictsContext';
import { BarangaysContext } from '../../../context/BarangaysContext';
import { SectionsContext } from '../../../context/SectionsContext';
import { ParcelsContext } from '../../../context/ParcelsContext';

const MainMapViewer = () => {
    const { district } = useContext(DistrictsContext);
    const { barangay, barangays } = useContext(BarangaysContext);
    const { section, sections } = useContext(SectionsContext);
    const { parcel, parcels } = useContext(ParcelsContext);

    // Ref to hold the map instance
    const mapRef = useRef(null);
    // A reference to the Layer Control
    const layerControlRef = useRef(null);

    // Individual layer
    const districtLayerRef = useRef(null); // A reference to the District GeoJSONLayer
    const barangayLayerRef = useRef(null); // A reference to the Barangay GeoJSONLayer
    const sectionLayerRef = useRef(null); // A reference to the Section GeoJSONLayer
    const parcelLayerRef = useRef(null); // A reference to the Parcel GeoJSONLayer

    // Layer Groups for overlays
    const barangayLayerGroupRef = useRef(null);
    const sectionLayerGroupRef = useRef(null);
    const parcelLayerGroupRef = useRef(null);

    // Function to reset layer and ref 
    const removeLayer = (layer, control, ref) => {
        if (layer) {
            layer.off(); // Remove all event listeners
            control.removeLayer(layer);
            control._map.removeLayer(layer);
            ref.current = null;
        }
    };

    // Function to highlight corresponding Leaflet layer
    const highlightLayer = (event) => {
        const layer = event.target;
        // Change the style to highlight
        layer.setStyle({
            "fillColor": '#003067',
            "weight": 3,
            "opacity": 1,
            "color": '#fce6ac',
            "fillOpacity": 1
        });
    };

    const resetHighlightLayer = (event, geoJSONLayer) => {
        const layer = event.target;
        // Restore the original style
        geoJSONLayer.resetStyle(layer);
    };

    // Function to handle layer click and fly to its bbox
    const flyToLayer = (event) => {
        const layer = event.target;
        const { bounds } = layer.options;
        mapRef.current.flyToBounds(bounds, {
            animate: true,
            duration: 1 // in seconds
        });
    };

    // Rendering for map
    useEffect(() => {
        // Initialize map if it's not already initialized
        if (!mapRef.current) {
            // Create a map instance and specify its center and zoom level
            const map = L.map('map', {
                minZoom: 12,
                maxZoom: 20
            }).setView([14.5826, 121.06481], 16);

            // Add a tile layer to the map
            const osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                minZoom: 12,
                maxZoom: 20,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            });

            const mapBox = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoibm9ybWFuc2tpIiwiYSI6ImNrazZuYzNtZzA1bTEyb3FzY283bHoza3AifQ.UC4JqPIrVr4i2wYJvBoK3w', {
                id: 'mapbox/light-v9',
                tileSize: 512,
                zoomOffset: -1,
                minZoom: 12,
                maxZoom: 20,
                tms: false,
                crs: L.CRS.EPSG3123,
                attribution: 'Pasig City CLIMA Project 2023'
            });

            var googleSat = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
                maxZoom: 20,
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
            });

            const baseMaps = {
                "OpenStreetMap": osm,
                "Map Box": mapBox,
                "Google Satellite": googleSat
            };

            const layerControl = L.control.layers(baseMaps, null);
            layerControl.addTo(map);

            // Set the googleSat layer as active in the layer control
            googleSat.addTo(map);

            // Assign the layer control to the ref
            layerControlRef.current = layerControl;

            // Assign the map instance to the ref
            mapRef.current = map;

            // Ensure map resizes properly
            map.invalidateSize();
        }
    }, []);

    // Cleanup event listeners on unmount
    useEffect(() => {
        return () => {
            const map = mapRef.current;
            const layerControl = layerControlRef.current;

            if (districtLayerRef.current) {
                map.removeLayer(districtLayerRef.current);
                districtLayerRef.current.off();
                districtLayerRef.current = null;
            }
            if (barangayLayerGroupRef.current) {
                removeLayer(barangayLayerGroupRef.current, layerControl, barangayLayerGroupRef);
            }
            if (sectionLayerGroupRef.current) {
                removeLayer(sectionLayerGroupRef.current, layerControl, sectionLayerGroupRef);
            }
            if (parcelLayerGroupRef.current) {
                removeLayer(parcelLayerGroupRef.current, layerControl, parcelLayerGroupRef);
            }
        };
    }, []);

    // Rendering for a District and its Barangays
    useEffect(() => {
        const map = mapRef.current;
        const layerControl = layerControlRef.current;

        if (district) {
            // Remove the previous districtLayer if it exists
            if (districtLayerRef.current) {
                map.removeLayer(districtLayerRef.current);
                districtLayerRef.current.off();
                districtLayerRef.current = null;
            }
            // Remove the previous barangayLayer if it exists
            if (barangayLayerRef.current) {
                removeLayer(barangayLayerRef.current, layerControl, barangayLayerRef);
            }
            // Remove the previous barangayLayerGroup if it exists
            if (barangayLayerGroupRef.current) {
                removeLayer(barangayLayerGroupRef.current, layerControl, barangayLayerGroupRef);
            }
            // Remove the previous sectionLayer if it exists
            if (sectionLayerRef.current) {
                removeLayer(sectionLayerRef.current, layerControl, sectionLayerRef);
            }
            // Remove the previous sectionLayerGroup if it exists
            if (sectionLayerGroupRef.current) {
                removeLayer(sectionLayerGroupRef.current, layerControl, sectionLayerGroupRef);
            }
            // Remove the previous parcelLayer if it exists
            if (parcelLayerRef.current) {
                removeLayer(parcelLayerRef.current, layerControl, parcelLayerRef);
            }
            // Remove the previous parcelLayerGroup if it exists
            if (parcelLayerGroupRef.current) {
                removeLayer(parcelLayerGroupRef.current, layerControl, parcelLayerGroupRef);
            }

            // BEGIN: Add the District layer
            const districtGeom =
            {
                "type": "Feature",
                "properties": {
                    "district_id": district.district_id,
                    "district_name": district.district_name,
                    "area": district.area,
                    "barangays_count": district.barangays_count
                },
                "geometry": district.geometry
            };

            const style = {
                "fillColor": 'none',
                "fillOpacity": 0.5,
                "color": '#ffeda0',
                "opacity": 1,
                "dashArray": '2',
                "weight": 2,
            };

            const { bounds } = parseBBox(JSON.stringify(district.bbox));
            const districtLayer = L.geoJSON(districtGeom, {
                style,
                bounds
            }).addTo(map);
            // Save districtLayer to the reference
            districtLayerRef.current = districtLayer;

            // Fly and zoom to bbox
            map.flyToBounds(bounds, {
                animate: true,
                duration: 1 // in seconds
            });
            // END: Add the District layer

            // BEGIN: Add Barangays layergroup
            // Check if there are barangays populated from the selected district
            if (barangays.length !== 0) {
                // create a layergroup for the Barangay layers
                const barangayLayerGroup = L.layerGroup();

                // Render all Barangays of a District and add to layerControl
                for (const barangay of barangays) {
                    // Add the Barangay layer
                    const barangayGeom = {
                        "type": "Feature",
                        "properties": {
                            "barangay_id": barangay.barangay_id,
                            "barangay_name": barangay.section_number,
                            "area": barangay.area,
                            "population": barangay.population,
                            "sections_count": barangay.sections_count
                        },
                        "geometry": barangay.geometry
                    };

                    const style = {
                        "fillColor": '#e5f5e0',
                        "fillOpacity": 0.5,
                        "color": '#003067',
                        "opacity": 1,
                        "dashArray": '2',
                        "weight": 2,
                    };

                    // Add the bbox in the layer.options
                    const { bounds } = parseBBox(JSON.stringify(barangay.bbox));
                    const barangayLayer = L.geoJSON(barangayGeom, {
                        style,
                        bounds,
                        onEachFeature: function (feature, layer) {
                            layer.on('mouseover', (event) => highlightLayer(event));
                            layer.on('mouseout', (event) => resetHighlightLayer(event, barangayLayer));
                            layer.on('click', (event) => flyToLayer(event));
                        }
                    });
                    barangayLayerGroup.addLayer(barangayLayer);
                }

                layerControl.addOverlay(barangayLayerGroup, "Barangays");

                barangayLayerGroup.addTo(map);
                // Assign to the ref
                barangayLayerGroupRef.current = barangayLayerGroup;
            } // END: Add Barangays layergroup
        }
        // eslint-disable-next-line
    }, [district, barangays]);

    // Rendering for a Barangay and its Sections
    useEffect(() => {
        const map = mapRef.current;
        const layerControl = layerControlRef.current;

        if (barangay) {
            // Remove the previous barangayLayer if it exists
            if (barangayLayerRef.current) {
                removeLayer(barangayLayerRef.current, layerControl, barangayLayerRef);
            }
            // Remove the previous sectionLayer if it exists
            if (sectionLayerRef.current) {
                removeLayer(sectionLayerRef.current, layerControl, sectionLayerRef);
            }
            // Remove the previous sectionLayerGroup if it exists
            if (sectionLayerGroupRef.current) {
                removeLayer(sectionLayerGroupRef.current, layerControl, sectionLayerGroupRef);
            }
            // Remove the previous parcelLayer if it exists
            if (parcelLayerRef.current) {
                removeLayer(parcelLayerRef.current, layerControl, parcelLayerRef);
            }
            // Remove the previous parcelLayerGroup if it exists
            if (parcelLayerGroupRef.current) {
                removeLayer(parcelLayerGroupRef.current, layerControl, parcelLayerGroupRef);
            }

            // BEGIN: Add the Barangay layer
            const barangayGeom = {
                "type": "Feature",
                "properties": {
                    "barangay_id": barangay.barangay_id,
                    "barangay_name": barangay.section_number,
                    "area": barangay.area,
                    "population": barangay.population,
                    "sections_count": barangay.sections_count
                },
                "geometry": barangay.geometry
            };

            const style = {
                "fillColor": 'none',
                "fillOpacity": 0.5,
                "color": '#ffeda0',
                "opacity": 1,
                "dashArray": '2',
                "weight": 2,
            };

            const { bounds } = parseBBox(JSON.stringify(barangay.bbox));
            const barangayLayer = L.geoJSON(barangayGeom, {
                style,
                bounds
            }).addTo(map);

            layerControl.addOverlay(barangayLayer, `Barangay ${barangay.barangay_name}`);
            // Save barangayLayer to the ref
            barangayLayerRef.current = barangayLayer;

            // Fly and zoom to bbox
            map.flyToBounds(bounds, {
                animate: true,
                duration: 1 // in seconds
            });
            // END: Add the Barangay layer

            // Need to remove the mouse events of the current barangay
            //const barangays = barangayLayerGroupRef.current;

            // BEGIN: Add Sections layergroup
            // Check if there are sections populated from the selected barangay
            if (sections.length !== 0) {
                // create a layergroup for the Section layers
                const sectionLayerGroup = L.layerGroup();

                // Render all Sections of a Barangay and add to layerControl
                for (const section of sections) {
                    // Add the Section layer
                    const sectionGeom = {
                        "type": "Feature",
                        "properties": {
                            "section_id": section.section_id,
                            "section_number": section.section_number,
                            "taxable_parcels_count": section.taxable_parcels_count,
                            "improved_parcels_count": section.improved_parcels_count,
                            "parcels_count": section.parcels_count
                        },
                        "geometry": section.geometry
                    };

                    const style = {
                        "fillColor": '#969696',
                        "fillOpacity": 0.5,
                        "color": '#003067',
                        "opacity": 1,
                        "dashArray": '2',
                        "weight": 2
                    };

                    const { bounds } = parseBBox(JSON.stringify(section.bbox));
                    const sectionLayer = L.geoJSON(sectionGeom, {
                        style,
                        bounds,
                        onEachFeature: function (feature, layer) {
                            layer.on('mouseover', (event) => highlightLayer(event));
                            layer.on('mouseout', (event) => resetHighlightLayer(event, sectionLayer));
                            layer.on('click', (event) => flyToLayer(event));
                        }
                    });
                    sectionLayerGroup.addLayer(sectionLayer);
                }

                layerControl.addOverlay(sectionLayerGroup, "Sections");

                sectionLayerGroup.addTo(map);
                // Assign to the ref
                sectionLayerGroupRef.current = sectionLayerGroup;
            } // END: Add Sections layergroup
        }
        // eslint-disable-next-line
    }, [barangay, sections]);

    // Rendering for a Section and its Parcels
    useEffect(() => {
        const map = mapRef.current;
        const layerControl = layerControlRef.current;

        if (section) {
            // Remove the previous sectionLayer if it exists
            if (sectionLayerRef.current) {
                removeLayer(sectionLayerRef.current, layerControl, sectionLayerRef);
            }
            // Remove the previous parcelLayer if it exists
            if (parcelLayerRef.current) {
                removeLayer(parcelLayerRef.current, layerControl, parcelLayerRef);
            }
            // Remove the previous parcelLayerGroup if it exists
            if (parcelLayerGroupRef.current) {
                removeLayer(parcelLayerGroupRef.current, layerControl, parcelLayerGroupRef);
            }

            // BEGIN: Add the Section layer
            const sectionGeom = {
                "type": "Feature",
                "properties": {
                    "section_id": section.section_id,
                    "section_number": section.section_number,
                    "taxable_parcels_count": section.taxable_parcels_count,
                    "improved_parcels_count": section.improved_parcels_count,
                    "parcels_count": section.parcels_count
                },
                "geometry": section.geometry
            };

            const style = {
                "fillColor": 'none',
                "fillOpacity": 0.5,
                "color": '#ffeda0',
                "opacity": 1,
                "dashArray": '2',
                "weight": 2,
            };

            const { bounds } = parseBBox(JSON.stringify(section.bbox));
            const sectionLayer = L.geoJSON(sectionGeom, {
                style,
                bounds,
            }).addTo(map);

            layerControl.addOverlay(sectionLayer, `Section ${section.section_number}`);

            // Save sectionLayer to the ref
            sectionLayerRef.current = sectionLayer;

            // Fly and zoom to bbox
            map.flyToBounds(bounds, {
                animate: true,
                duration: 1 // in seconds
            });
            // END: Add the Section layer

            // BEGIN: Add Parcels layergroup
            // Check if there are parcels populated from the selected section
            if (parcels.length !== 0) {
                // create a layergroup for the Section layers
                const parcelLayerGroup = L.layerGroup();

                // Render all Parcels of a Section and add to layerControl
                for (const parcel of parcels) {

                    // Add the Parcel layer
                    const parcelGeom = {
                        "type": "Feature",
                        "properties": {
                            "parcel_id": parcel.parcel_id,
                            "pin": parcel.parcel_pin,
                            "parcel_number": parcel.parcel_number,
                            "area": parcel.area,
                            "taxable": parcel.taxable,
                            "improved": parcel.improved
                        },
                        "geometry": parcel.geometry
                    };

                    const style = {
                        "fillColor": '#99d8c9',
                        "fillOpacity": 0.5,
                        "color": '#003067',
                        "opacity": 1,
                        "dashArray": '2',
                        "weight": 2
                    };

                    const { bounds } = parseBBox(JSON.stringify(parcel.bbox));
                    const parcelLayer = L.geoJSON(parcelGeom, {
                        style,
                        bounds,
                        onEachFeature: (feature, layer) => {
                            layer.on('mouseover', (event) => highlightLayer(event));
                            layer.on('mouseout', (event) => resetHighlightLayer(event, parcelLayer));
                            layer.on('click', (event) => flyToLayer(event));
                        }
                    });
                    parcelLayerGroup.addLayer(parcelLayer);
                }

                layerControl.addOverlay(parcelLayerGroup, "Parcels");

                parcelLayerGroup.addTo(map);
                // Assign to the ref
                parcelLayerGroupRef.current = parcelLayerGroup;
            } // END: Add Parcels layergroup
        }
        // eslint-disable-next-line
    }, [section, parcels]);

    // Rendering for a Parcel
    useEffect(() => {
        const map = mapRef.current;
        const layerControl = layerControlRef.current;

        if (parcel) {
            // Remove the previous parcelLayer if it exists
            if (parcelLayerRef.current) {
                removeLayer(parcelLayerRef.current, layerControl, parcelLayerRef);
            }

            // BEGIN: Add the Parcel layer
            const parcelGeom = {
                "type": "Feature",
                "properties": {
                    "parcel_id": parcel.parcel_id,
                    "pin": parcel.parcel_pin,
                    "parcel_number": parcel.parcel_number,
                    "area": parcel.area,
                    "taxable": parcel.taxable,
                    "improved": parcel.improved
                },
                "geometry": parcel.geometry
            };

            const style = {
                "fillColor": '#ffeda0',
                "fillOpacity": 0.5,
                "color": '#003067',
                "opacity": 1,
                "weight": 2
            };

            const { bounds } = parseBBox(JSON.stringify(parcel.bbox));
            const parcelLayer = L.geoJSON(parcelGeom, {
                style,
                bounds,
                onEachFeature: (feature, layer) => {
                    layer.on('mouseover', (event) => highlightLayer(event));
                    layer.on('mouseout', (event) => resetHighlightLayer(event, parcelLayer));
                    layer.on('click', (event) => flyToLayer(event));
                }
            }).addTo(map);

            layerControl.addOverlay(parcelLayer, `Parcel ${parcel.parcel_number}`);
            // Save sectionLayer to the ref
            parcelLayerRef.current = parcelLayer;

            // Fly and zoom to bbox
            map.flyToBounds(bounds, {
                animate: true,
                duration: 1 // in seconds
            });
        }
        // eslint-disable-next-line
    }, [parcel]);

    return (
        <Card sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', p: 0 }}>
                <div id="map" style={{ flex: '0 1 auto', minHeight: '620px', width: '100%' }}></div>
            </CardContent>
        </Card>

        // <Card sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
        //     <CardContent sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', p: 0 }}>
        //         <div id="map" style={{ flex: '1 1 auto', width: '100%', height: '100%' }}></div>
        //     </CardContent>
        // </Card>
    );
};

export default MainMapViewer;
