import React, { useState, useEffect, useContext } from 'react';

import { Typography, Box, Button, Collapse, Slider } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { DistrictsContext } from '../../../context/DistrictsContext';
import { BarangaysContext } from '../../../context/BarangaysContext';
import { SectionsContext } from '../../../context/SectionsContext';
import { ParcelsContext } from '../../../context/ParcelsContext';
import { TaxParcelsContext } from '../../../context/TaxParcelsContext';
import { MapContext } from '../../../context/MapContext';

import { parseBBox } from '../../../utils/parseBBox';

import ATMSSModal from '../common/ATMSSModal';
import IconComponent from '../common/IconComponent';
import LeafletStyles from '../../../assets/LeafletStyles';
import renderMessageWithLineBreaks from '../../../utils/renderMessageWithLineBreaks';

const ParcelFabric = () => {
    // Declare variables to store params from localStorage
    const { setDistrict } = useContext(DistrictsContext);
    const { setBarangay } = useContext(BarangaysContext);
    const { section, setSection } = useContext(SectionsContext);
    const { parcel, neighbors, neighborLayerGroup, setParcel, fetchNeighbors, setShowNeighbors } = useContext(ParcelsContext);
    const { taxParcel, taxParcels, taxParcelLayerGroup, setTaxParcel, setShowTaxParcels, fetchLastTaxParcelNumber, fetchTaxParcelsFromParcel } = useContext(TaxParcelsContext);
    const {
        map, mapMessage, setMapMessage, infoControl, setUserInitiatedChange, setIsNewlyCreated,
        enableCreateTools, setEnableCreateTools, enableEditTools, setEnableEditTools, isPartitioned, setShowPartitions, partitionLayerGroup
    } = useContext(MapContext);

    const [type, setType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [highlightedLayer, setHighlightedLayer] = useState(null);
    const [buffer, setBuffer] = useState(0);
    const [partitionIds, setPartitionIds] = useState([]);

    const [expanded, setExpanded] = useState({
        neighborButton: false,
        taxParcelsButton: false,
        partitionsButton: true
    });

    const [modalContent, setModalContent] = useState({
        singleButton: "enabled",
        onCloseText: "Close",
        onAcceptText: "Accept",
        title: "Default Title",
        message: "Default message content."
    });

    const handleExpand = (buttonName) => {
        setExpanded((prevState) => ({
            ...prevState,
            [buttonName]: !prevState[buttonName],
        }));
    };

    // Modal handling
    const handleOpenModal = (toolType, source) => {
        if (enableCreateTools) {
            setModalContent({
                singleButton: "enabled",
                onAcceptText: "OK",
                title: "Information: Creating a New Tax Parcel",
                message: `The tool is already enabled.
              \nPlease check the GeoTools on the left side of the window.`
            });
        }
        else if (enableEditTools) {
            setModalContent({
                singleButton: "enabled",
                onAcceptText: "OK",
                title: "Information: Editing a Tax Parcel",
                message: `The tool is already enabled.
              \nPlease check the GeoTools on the left side of the window.`
            });
        }
        else {
            if (toolType === 'createTaxParcel') {
                if (source.taxable === 'yes') {
                    setModalContent({
                        onCloseText: "Cancel",
                        onAcceptText: "Confirm",
                        title: "Information: Creating a New Tax Parcel",
                        message: `Are you sure you want to create a new Tax Parcel in\nParcel PIN: ${source.parcel_pin}.`
                    });
                }
                else {
                    setModalContent({
                        onCloseText: "Cancel",
                        onAcceptText: "Confirm",
                        title: "Warning: Creating a New Tax Parcel",
                        message: `Are you sure you want to create a new Tax Parcel in\nParcel PIN: ${source.parcel_pin}.
              \nCreating this will make the Parcel type changed to "taxable"`
                    });
                }
            }
            else if (toolType === 'editTaxParcel') {
                setModalContent({
                    onCloseText: "Cancel",
                    onAcceptText: "Confirm",
                    title: "Information: Editing a Tax Parcel",
                    message: `Are you sure you want to edit the Tax Parcel with\nT-PIN: ${source.tax_parcel_pin}.`
                });
            }
        }
        setType(toolType);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false)
    };

    // Handles the Accept scenario
    const handleAcceptModal = (event) => {
        if (type === 'createTaxParcel') {
            setEnableCreateTools(true);
            setUserInitiatedChange(true);
            setIsNewlyCreated(true);
        }
        if (type === 'editTaxParcel') {
            setEnableEditTools(true);
            setUserInitiatedChange(true);
        }
        setShowModal(false);
        setModalContent({ // Set back to default
            singleButton: "enabled",
            onCloseText: "Close",
            onAcceptText: "Accept",
            title: "Default Title",
            message: "Default message content."
        });
    };

    // Get layer from a layergroup
    const getLayer = (parcelId, layerGroup, type) => {
        let layer = null;
        if (layerGroup) {
            const layers = layerGroup.getLayers();
            if (type === 'neighbor') {
                layer = layers.find(layer => layer.toGeoJSON().features[0].properties.parcel_id === parcelId);
            }
            if (type === 'taxParcel') {
                layer = layers.find(layer => layer.toGeoJSON().features[0].properties.tax_parcel_id === parcelId);
            }
            if (type === 'partition') {
                layer = layers.find(layer => layer.toGeoJSON().features[0].properties.partition_id === parcelId);
            }
        }
        return layer;
    };

    // Function to get and set again the taxParcel geojson coming from refresh
    const handleTaxParcel = (toolType, taxParcelId) => {
        const tParcel = taxParcels.find(taxParcel => taxParcel.tax_parcel_id === taxParcelId);
        if (!taxParcel) {
            setTaxParcel(tParcel);
        }
        handleOpenModal(toolType, tParcel);

        flyToLayer(taxParcelId, 'taxParcel');
    };

    // Function to highlight corresponding Leaflet layer
    const highlightLayer = (parcelId, type) => {
        let layerGroup = null;
        if (type === 'neighbor') {
            layerGroup = neighborLayerGroup;
        }
        if (type === 'taxParcel') {
            layerGroup = taxParcelLayerGroup;
        }
        if (type === 'partition') {
            layerGroup = partitionLayerGroup;
        }

        const layer = getLayer(parcelId, layerGroup, type);
        if (layer) {
            const props = layer.toGeoJSON().features[0].properties;
            const type = layer.options.type;
            const info = infoControl;
            layer.setStyle(LeafletStyles.highlightLayer);
            if (info) {
                info.update(props, type);
            }
            setHighlightedLayer(layer);
        }
    };

    const resetHighlightLayer = (type) => {
        if (highlightedLayer) {
            if (type === 'neighbor') {
                highlightedLayer.setStyle(LeafletStyles.neighborLayer);
            }
            if (type === 'taxParcel') {
                highlightedLayer.setStyle(LeafletStyles.taxParcelLayer);
            }
            if (type === 'partition') {
                highlightedLayer.setStyle(LeafletStyles.partitionLayer);
            }
            setHighlightedLayer(null);
        }
    };

    const flyToLayer = (parcelId, type) => {
        let layerGroup = null;
        if (type === 'neighbor') {
            layerGroup = neighborLayerGroup;
        }
        if (type === 'taxParcel') {
            layerGroup = taxParcelLayerGroup;
        }
        if (type === 'partition') {
            layerGroup = partitionLayerGroup;
        }

        const layer = getLayer(parcelId, layerGroup, type);
        if (layer) {
            const bounds = layer.options.bounds;

            // Fly and zoom to bbox
            map.flyToBounds(bounds, {
                animate: true,
                duration: 1 // in seconds
            });
        }
    };

    const flyToParcel = () => {
        if (parcel) {
            const { bounds } = parseBBox(JSON.stringify(parcel.bbox));

            // Fly and zoom to bbox
            map.flyToBounds(bounds, {
                animate: true,
                duration: 1 // in seconds
            });
        }
    };

    const handleSliderChange = (event, newValue) => {
        setBuffer(newValue);
    };

    // Initialize the window
    useEffect(() => {
        // Retrieve the params data from localStorage
        const paramsData = localStorage.getItem('paramsData');

        if (paramsData) {
            try {
                // Parse the params data string to an object
                const params = JSON.parse(paramsData);

                // Rebuild the context in this component
                if (params) {
                    setDistrict(params.district);
                    setBarangay(params.barangay);
                    setSection(params.section);
                    setParcel(params.parcel);
                }

            } catch (error) {
                console.error('Error parsing params data:', error);
            }
        }

        // Remove the right-click "contextmenu" behaviour
        document.addEventListener('contextmenu', function (event) {
            if (!event.target.matches('.allowed-button')) {
                event.preventDefault();
            }
        });

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('contextmenu', function (event) {
                event.preventDefault();
            });
        };
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (section) {
            //get last tax parcel number
            fetchLastTaxParcelNumber(section.section_id);
        }
        // eslint-disable-next-line
    }, [section]);

    useEffect(() => {
        if (parcel) {
            fetchNeighbors(parcel.parcel_id, buffer);
            fetchTaxParcelsFromParcel(parcel.parcel_id);
        }
        // eslint-disable-next-line
    }, [parcel]);

    useEffect(() => {
        if (parcel) {
            fetchNeighbors(parcel.parcel_id, buffer);
        }
        // eslint-disable-next-line
    }, [buffer]);

    // Handle when a new or a change in tax parcel happens
    useEffect(() => {
        if (taxParcel) {
            fetchTaxParcelsFromParcel(parcel.parcel_id);
        }
        // eslint-disable-next-line
    }, [taxParcel]);

    // Handles the show/hide event
    useEffect(() => {
        if (expanded.neighborButton) {
            setShowNeighbors(true);
        } else {
            setShowNeighbors(false);
        }

        if (expanded.taxParcelsButton) {
            setShowTaxParcels(true);
        } else {
            setShowTaxParcels(false);
        }

        if (expanded.partitionsButton) {
            setShowPartitions(true);
        } else {
            setShowPartitions(false);
        }
        // eslint-disable-next-line
    }, [expanded]);

    // Handles messages from MapViewer
    useEffect(() => {
        if (mapMessage) {
            const { type, content } = mapMessage;

            if (type === 'tools') {
                const { toolType, sourceId } = content;

                handleTaxParcel(toolType, sourceId);

                setMapMessage(null);
            }
            if (type === 'partitions') {
                const { partitionIds } = content;

                setPartitionIds(partitionIds);

                setMapMessage(null);
            }
        }
        // eslint-disable-next-line
    }, [mapMessage]);

    return (
        <Box p={2} width="100%" >
            <Typography variant="h6" fontWeight="bold">
                Cadastral Data Fabric
            </Typography>

            <Box borderTop={1} borderColor="divider" my={2} />

            <Box mb={1}>
                {parcel && (
                    <Box mt={0} pl={0}>
                        <Typography variant="h7" fontWeight="bold">
                            PIN: {parcel ? parcel.parcel_pin : 'N/A'}
                        </Typography>
                        <Typography variant="body2">
                            <strong>plan #:</strong> {parcel.plan_number}
                        </Typography>
                        <Typography variant="body2">
                            <strong>area:</strong> {parcel.area} sq.m.
                        </Typography>
                        <Typography variant="body2">
                            <strong>taxable:</strong> {parcel.taxable}
                        </Typography>
                        <Typography variant="body2">
                            <strong>improved:</strong> {parcel.improved}
                        </Typography>
                    </Box>
                )}
            </Box>

            <Box borderTop={1} borderColor="divider" my={2} />

            <Box mb={1}>
                <Button
                    variant="text"
                    color="primary"
                    endIcon={expanded.neighborButton ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => handleExpand('neighborButton')}
                    fullWidth
                    sx={{
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                        gap: 1,
                        fontWeight: 'bold',
                        fontSize: '1rem',
                    }}
                >
                    <IconComponent iconType="barangay" />{' '}
                    {expanded.neighborButton ? 'Hide Neighbors' : 'Show Neighbors'}
                </Button>
                <Collapse in={expanded.neighborButton}>
                    <Box
                        mt={0}
                        mb={0}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center', // Center items vertically
                            justifyContent: 'center', // Center items horizontally
                            textAlign: 'center', // Center text within Typography
                            mx: 'auto' // Center the Box itself if needed
                        }}
                    >
                        <Typography variant="body2" >
                            <strong>Distance:</strong> {buffer} meters
                        </Typography>
                        <Slider
                            value={buffer}
                            onChange={handleSliderChange}
                            aria-labelledby="buffer-distance-slider"
                            min={0}
                            max={500}
                            step={1}
                            valueLabelDisplay="auto"
                            marks
                        />
                    </Box>
                    <Box sx={{ maxHeight: 150, overflowY: 'auto' }}>
                        {neighbors && neighbors.length > 0 ? (
                            neighbors.map((neighbor) => (
                                <Box key={neighbor.parcel_pin} my={0}>
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        fullWidth
                                        sx={{
                                            textTransform: 'none',
                                            justifyContent: 'flex-start',
                                            gap: 0,
                                        }}
                                        onMouseEnter={() => highlightLayer(neighbor.parcel_id, 'neighbor')}
                                        onMouseLeave={() => resetHighlightLayer('neighbor')}
                                        onClick={() => flyToLayer(neighbor.parcel_id, 'neighbor')}
                                    >
                                        PIN: {neighbor.parcel_pin}
                                    </Button>
                                </Box>
                            ))
                        ) : (
                            <Typography variant="body2" color="textSecondary">
                                No Neighboring Parcels available.
                            </Typography>
                        )}
                    </Box>
                </Collapse>
            </Box>

            <Box borderTop={1} borderColor="divider" my={2} />

            <Box mb={1}>
                <Button
                    variant="text"
                    color="primary"
                    endIcon={expanded.taxParcelsButton ? <ExpandLess /> : <ExpandMore />}
                    onClick={() => handleExpand('taxParcelsButton')}
                    fullWidth
                    sx={{
                        textTransform: 'none',
                        justifyContent: 'flex-start',
                        gap: 1,
                        fontWeight: 'bold',
                        fontSize: '1rem',
                    }}
                >
                    <IconComponent iconType="parcel" />{' '}
                    {expanded.taxParcelsButton ? 'Hide Tax Parcels' : 'Show Tax Parcels'}
                </Button>
                <Collapse in={expanded.taxParcelsButton}>
                    {taxParcels && taxParcels.length > 0 && (
                        <Box my={1} pl={2}>
                            <Typography variant="body2">
                                <strong>Right-click to edit</strong>
                            </Typography>
                        </Box>
                    )}
                    <Box>
                        {taxParcels && taxParcels.length > 0 ? (
                            taxParcels.map((taxParcel) => (
                                <Box key={taxParcel.tax_parcel_pin} my={1}>
                                    <Button
                                        variant="text"
                                        color="inherit"
                                        fullWidth
                                        sx={{
                                            textTransform: 'none',
                                            justifyContent: 'flex-start',
                                            gap: 1,
                                        }}
                                        onMouseEnter={() => highlightLayer(taxParcel.tax_parcel_id, 'taxParcel')}
                                        onMouseLeave={() => resetHighlightLayer('taxParcel')}
                                        onClick={() => flyToLayer(taxParcel.tax_parcel_id, 'taxParcel')}
                                        onContextMenu={(e) => {
                                            handleTaxParcel('editTaxParcel', taxParcel.tax_parcel_id);
                                            e.preventDefault(); // Prevent the default context menu
                                        }}
                                    >
                                        PIN: {taxParcel ? taxParcel.tax_parcel_pin : 'N/A'}
                                    </Button>
                                </Box>
                            ))
                        ) : (
                            <Box>
                                <Typography variant="body2" color="textSecondary">
                                    No Tax Parcels in the database.
                                </Typography>
                                <Button
                                    variant="text"
                                    color="primary"
                                    fullWidth
                                    sx={{
                                        textTransform: 'none',
                                        justifyContent: 'flex-start',
                                        gap: 1,
                                    }}
                                    onClick={() => {
                                        handleOpenModal('createTaxParcel', parcel);
                                        flyToParcel();
                                    }}
                                >
                                    Create a new Tax Parcel?
                                </Button>
                            </Box>
                        )}
                    </Box>
                </Collapse>
            </Box>

            <Box borderTop={1} borderColor="divider" my={2} />

            {isPartitioned && (
                <>
                    <Box mb={1}>
                        <Button
                            variant="text"
                            color="primary"
                            endIcon={expanded.partitionsButton ? <ExpandLess /> : <ExpandMore />}
                            onClick={() => handleExpand('partitionsButton')}
                            fullWidth
                            sx={{
                                textTransform: 'none',
                                justifyContent: 'flex-start',
                                gap: 1,
                                fontWeight: 'bold',
                                fontSize: '1rem',
                            }}
                        >
                            <IconComponent iconType="section" />{' '}
                            {expanded.partitionsButton ? 'Hide Segregations' : 'Show Segregations'}
                        </Button>
                        <Collapse in={expanded.partitionsButton}>
                            <Box>
                                {partitionIds.length > 0 &&
                                    partitionIds.map((partitionId) => (
                                        <Box key={partitionId} my={1}>
                                            <Button
                                                variant="text"
                                                color="inherit"
                                                fullWidth
                                                sx={{
                                                    textTransform: 'none',
                                                    justifyContent: 'flex-start',
                                                    gap: 1,
                                                }}
                                                onMouseEnter={() => highlightLayer(partitionId, 'partition')}
                                                onMouseLeave={() => resetHighlightLayer('partition')}
                                                onClick={() => flyToLayer(partitionId, 'partition')}
                                                onContextMenu={(e) => {
                                                    // handleTaxParcel('editTaxParcel', taxParcel.tax_parcel_id);
                                                    e.preventDefault(); // Prevent the default context menu
                                                }}
                                            >
                                                Part: {partitionId ? partitionId : 'N/A'}
                                            </Button>
                                        </Box>
                                    ))}
                            </Box>
                        </Collapse>
                    </Box>
                    <Box borderBottom={1} borderColor="divider" my={1} />
                </>
            )}

            {/* Modal */}
            <ATMSSModal
                show={showModal}
                singleButton={modalContent.singleButton}
                onCloseText={modalContent.onCloseText}
                onAcceptText={modalContent.onAcceptText}
                onClose={handleCloseModal}
                onAccept={handleAcceptModal}
                title={modalContent.title}
            >
                <Typography>
                    {renderMessageWithLineBreaks(modalContent.message)}
                </Typography>
            </ATMSSModal>
        </Box>
    );
};

export default ParcelFabric;
