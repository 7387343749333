import * as turf from '@turf/turf';
import { turfPolygonToLPolygon } from './turfPolygonToLPolygon';

/**
 * Splits a polygon into smaller polygons using a polyline.
 * 
 * @param {object} polygonLayer - The polygon layer in GeoJSON format (e.g L.Polygon).
 * @param {object} polylineLayer - The polyline layer in GeoJSON format (e.g. event.layer).
 * @param {string} idPrefix - A prefix for the IDs of the resulting polygons.
 * @returns {Array|null} An array of polygon layer in GeoJSON format, or null if invalid input or no intersection (e.g. Array of L.Polygon).
 */

export const splitPolygon = (polygonLayer, polylineLayer) => {
    // lng, lat coords
    const polygonCoords = polygonLayer.toGeoJSON().geometry.coordinates;
    const polylineCoords = polylineLayer.toGeoJSON().geometry.coordinates;

    // Convert into turf geometries
    const polygon = turf.polygon(polygonCoords);
    const outerRing = turf.polygon([polygonCoords[0]]);
    const line = turf.lineString(polylineCoords);
    const idPrefix = polygonLayer.options.id;

    const THICK_LINE_UNITS = 'meters';
    const THICK_LINE_WIDTH = 0.000001;
    let thickLineString, thickLinePolygon, clipped, polyg, intersect;
    let intersectPoints;
    let cutFeatures = [];
    let offsetLine = [];
    let retVal = null;

    if (((polygon.geometry.type !== 'Polygon') && (polygon.geometry.type !== 'MultiPolygon')) || (line.geometry.type !== 'LineString')) {
        return retVal;
    }

    intersectPoints = turf.lineIntersect(polygon, line);
    if (intersectPoints.features.length === 0) {
        return retVal;
    }

    let lineCoords = turf.getCoords(line);
    if ((turf.booleanWithin(turf.point(lineCoords[0]), outerRing) ||
        (turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), outerRing)))) {
        alert("Cutting lines will not partition some of the polygons. Make sure they pass through them");
        return retVal;
    }

    offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });
    offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, { units: THICK_LINE_UNITS });

    for (let i = 0; i <= 1; i++) {
        let forCut = i;
        let forSelect = (i + 1) % 2;
        let polyCoords = [];

        for (let j = 0; j < line.geometry.coordinates.length; j++) {
            polyCoords.push(line.geometry.coordinates[j]);
        }

        for (let j = (offsetLine[forCut].geometry.coordinates.length - 1); j >= 0; j--) {
            polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
        }
        polyCoords.push(line.geometry.coordinates[0]);

        thickLineString = turf.lineString(polyCoords);
        thickLinePolygon = turf.lineToPolygon(thickLineString);
        clipped = turf.difference(polygon, thickLinePolygon);

        let cutPolyGeoms = [];
        for (let j = 0; j < clipped.geometry.coordinates.length; j++) {
            polyg = turf.polygon(clipped.geometry.coordinates[j]);
            intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
            if (intersect.features.length > 0) {
                cutPolyGeoms.push(polyg.geometry.coordinates);
            };
        };

        cutPolyGeoms.forEach(function (geometry, index) {
            let id = idPrefix + '-' + (i + 1) + '.' + (index + 1);
            const turfPolygon = turf.polygon(geometry, { id });
            const LPolygon = turfPolygonToLPolygon(turfPolygon, 'partition');
            LPolygon.setStyle({ color: '#55ffff' });
            cutFeatures.push(LPolygon);
        });
    }

    return cutFeatures;
};