import React, { useContext } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

// import context providers
import { SpatialDataContextProvider } from './context/SpatialDataContext';
import { MapContextProvider } from './context/MapContext';
import { CustomThemeProvider } from './context/ThemeContext';
import { AuthContext, AuthProvider } from './context/AuthContext';

// import bootstrap pages
// import ATMSSMain from './pages/bootstrap/ATMSSMain';
// import ParcelManager from './pages/bootstrap/ParcelManager';
import SectionManager from './pages/bootstrap/SectionManager';
// import LoginPage from './pages/bootstrap/auth/LoginPage';
// import ApprovalSuccessPage from './pages/bootstrap/admin/ApprovalSuccessPage';
// import ApprovalErrorPage from './pages/bootstrap/admin/ApprovalErrorPage';
// import AdminDashboard from './pages/bootstrap/admin/AdminDashboard';

//import material_ui pages
// import MainLandingPage from './pages/material_ui/MainLandingPage';
import LoginPage from './pages/material_ui/LoginPage';
import ATMSSMainPage from './pages/material_ui/ATMSSMainPageNoLogin';
import ParcelManagerPage from './pages/material_ui/ParcelManagerPageNoLogin';

import AboutPage from './pages/material_ui/misc/AboutPage';
import PrivacyPage from './pages/material_ui/misc/PrivacyPage';
import TermsPage from './pages/material_ui/misc/TermsPage';
import DisclaimerPage from './pages/material_ui/misc/DisclaimerPage';
//import AccessDeniedPage from './pages/material_ui/misc/AccessDeniedPage'; // Import Access Denied Page

const AppRoutes = () => {
    //const { isAuthenticated } = useContext(AuthContext);

    // Utility function to handle role-based access
    // const RequireRole = ({ roles, children }) => {
    //     if (!isAuthenticated) {
    //         return <Navigate to='/login' />;
    //     }
    //     if (!roles.some(role => userRole.includes(role))) {
    //         return <AccessDeniedPage />;
    //     }
    //     return children;
    // };

    return (
        <Routes>
            {/* <Route path='/' element={isAuthenticated ? <Navigate to='/atmss-main' /> : <Navigate to='/login' />} /> */}
            {/* <Route path='/login' element={isAuthenticated ? <Navigate to='/' /> : <LoginPage />} /> */}
            {/* <Route path='/atmss-main' element={isAuthenticated ? <ATMSSMainPage /> : <Navigate to='/login' />} /> */}
            <Route path='/' element={<Navigate to='/atmss-main' />} />
            <Route path='/atmss-main' element={<ATMSSMainPage />} />

            <Route path='/parcel-manager' element={<ParcelManagerPage />} />
            <Route path='/section-manager' element={<SectionManager />} />

            <Route path='/privacy' element={<PrivacyPage />} />
            <Route path='/terms' element={<TermsPage />} />
            <Route path='/disclaimer' element={<DisclaimerPage />} />
            <Route path='/about' element={<AboutPage />} />

            {/* <Route path='/section-manager' element={
                <RequireRole roles={['mapper', 'reviewer']}>
                    <SectionManager />
                </RequireRole>
            } /> */}
        </Routes>
    );
};

const App = () => {
    return (
        <AuthProvider>
            <SpatialDataContextProvider>
                <MapContextProvider>
                    <CustomThemeProvider>
                        <Router>
                            <AppRoutes />
                        </Router>
                    </CustomThemeProvider>
                </MapContextProvider>
            </SpatialDataContextProvider>
        </AuthProvider>
    );
};

export default App;
