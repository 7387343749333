import React, { useState, createContext, useCallback } from 'react';
import DistrictFinder from '../apis/DataFinder';

// Context for managing Districts
export const DistrictsContext = createContext();

export const DistrictsContextProvider = (props) => {
    const [districts, setDistricts] = useState([]);
    const [district, setDistrict] = useState(null);

    // Fetch Districts from Server
    const fetchDistrictsFromServer = useCallback(async () => {
        try {
            const response = await DistrictFinder.get('/districts');
            setDistricts(response.data);
        } catch (error) {
            console.error('Failed to fetch Districts from Server', error);
            setDistricts([]);
        }
    }, []); // No dependencies since fetchDistrictsFromServer does not depend on any external variables

    // Fetch District
    const fetchDistrict = useCallback(async (districtId) => {
        try {
            setDistrict(null);
            if (districtId !== 'default' && districtId !== undefined) {
                const response = await DistrictFinder.get(`/districts/${districtId}`);
                setDistrict(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch District.', error);
            setDistrict(null);
        }
    }, []); // No dependencies since fetchDistrict does not depend on any external variables

    return (
        <DistrictsContext.Provider value={{
            districts, district,
            setDistricts, setDistrict, fetchDistrictsFromServer, fetchDistrict
        }}>
            {props.children}
        </DistrictsContext.Provider>
    );
};
