import React, { useState, useContext, useEffect } from 'react';

import { Box, Button, Collapse, Typography, Fade, CircularProgress } from '@mui/material';
import { ExpandMore, ExpandLess } from '@mui/icons-material';

import { DistrictsContext } from '../../../context/DistrictsContext';
import { BarangaysContext } from '../../../context/BarangaysContext';
import { SectionsContext } from '../../../context/SectionsContext';
import { ParcelsContext } from '../../../context/ParcelsContext';

import ATMSSModal from '../common/ATMSSModal';
import IconComponent from '../common/IconComponent';
import renderMessageWithLineBreaks from '../../../utils/renderMessageWithLineBreaks';

const MainInfo = () => {
    const { district } = useContext(DistrictsContext);
    const { barangay } = useContext(BarangaysContext);
    const { section } = useContext(SectionsContext);
    const { parcel } = useContext(ParcelsContext);

    const [type, setType] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [openSections, setOpenSections] = useState({
        district: false,
        barangay: false,
        section: false,
        parcel: false,
    });

    const [modalContent, setModalContent] = useState({
        onCloseText: "Close",
        onAcceptText: "Accept",
        title: "Default Title",
        message: "Default message content."
    });

    const [isFading, setIsFading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const handleOpenModal = (type) => {
        if (section !== null && type === 'manageSection') {
            setModalContent({
                onCloseText: "Cancel",
                onAcceptText: "Confirm",
                title: "Navigating to Section Manager",
                message: `Are you sure you want to manage this \nSection: '${section.section_pin}'?`
            });
        }
        if (parcel !== null && type === 'manageParcel') {
            setModalContent({
                onCloseText: "Cancel",
                onAcceptText: "Confirm",
                title: "Navigating to Parcel Manager",
                message: `Are you sure you want to manage this \nParcel PIN: ${parcel.parcel_pin}?`
            });
        }

        setType(type);
        setShowModal(true);
    };

    const handleCloseModal = () => setShowModal(false);

    const handleAcceptModal = () => {
        const params = {
            district,
            barangay,
            section,
            parcel,
        };
        localStorage.setItem('paramsData', JSON.stringify(params));

        setIsFading(true); // Start the fade-out effect
        setIsLoading(true); // Show loading indicator

        setTimeout(() => {
            let url = 'https://pasigcity.gov.ph/'; // Default page is the pasig website
            if (section !== null && type === 'manageSection') {
                url = '/section-manager';
            }
            if (parcel !== null && type === 'manageParcel') {
                url = '/parcel-manager';
            }

            // Open URL in a new window
            window.open(url, '_blank');

            // After navigation
            setIsFading(false);
            setIsLoading(false);
            setShowModal(false); // Close modal
        }, 500); // Match the fade duration
    };

    const handleToggleCollapse = (section) => {
        setOpenSections((prev) => ({
            ...prev,
            [section]: !prev[section],
        }));
    };

    useEffect(() => {
        setOpenSections({
            district: district !== null,
            barangay: barangay !== null,
            section: section !== null,
            parcel: parcel !== null,
        });
    }, [district, barangay, section, parcel]);

    return (
        <Box>
            <Fade in={!isLoading && !isFading} timeout={500}>
                <Box p={2}>
                    <Typography variant="h6" fontWeight="bold">
                        Basic Information
                    </Typography>
                    <Box borderTop={1} borderColor="divider" my={2} />
                    <Box>
                        <Box mb={1} >
                            <Button
                                variant="text"
                                color="primary"
                                endIcon={openSections.district ? <ExpandLess /> : <ExpandMore />}
                                onClick={() => handleToggleCollapse('district')}
                                fullWidth
                                sx={{ 
                                    textTransform: 'none', 
                                    justifyContent: 'flex-start',
                                    gap: 1, 
                                    fontWeight: 'bold',
                                    fontSize: '1rem'
                                }}
                            >
                                <IconComponent iconType="district" /> District
                            </Button>
                            <Collapse in={openSections.district}>
                                {district && (
                                    <Box mt={0} p={1} border={0} pl={0}>
                                        <Typography variant="body2">
                                            <strong>name:</strong> {district.district_name}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>area:</strong> {district.area} hectares
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>number of barangays:</strong> {district.barangays_count}
                                        </Typography>
                                    </Box>
                                )}
                            </Collapse>
                        </Box>
                        <Box mb={1}>
                            <Button
                                variant="text"
                                color="primary"
                                endIcon={openSections.barangay ? <ExpandLess /> : <ExpandMore />}
                                onClick={() => handleToggleCollapse('barangay')}
                                fullWidth
                                sx={{ 
                                    textTransform: 'none', 
                                    justifyContent: 'flex-start',
                                    gap: 1, 
                                    fontWeight: 'bold',
                                    fontSize: '1rem'
                                }}
                            >
                                <IconComponent iconType="barangay" /> Barangay
                            </Button>
                            <Collapse in={openSections.barangay}>
                                {barangay && (
                                    <Box mt={0} p={1} border={0} pl={0}>
                                        <Typography variant="body2">
                                            <strong>name:</strong> {barangay.barangay_name}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>population:</strong> {barangay.population}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>number of sections:</strong> {barangay.sections_count}
                                        </Typography>
                                    </Box>
                                )}
                            </Collapse>
                        </Box>
                        <Box mb={1}>
                            <Button
                                variant="text"
                                color="primary"
                                endIcon={openSections.section ? <ExpandLess /> : <ExpandMore />}
                                onClick={() => handleToggleCollapse('section')}
                                fullWidth
                                sx={{ 
                                    textTransform: 'none', 
                                    justifyContent: 'flex-start',
                                    gap: 1, 
                                    fontWeight: 'bold',
                                    fontSize: '1rem'
                                }}
                            >
                                <IconComponent iconType="section" /> Section
                            </Button>
                            <Collapse in={openSections.section}>
                                {section && (
                                    <Box mt={0} p={1} border={0} pl={0}>
                                        <Typography variant="body2">
                                            <strong>section #:</strong> {section.section_number}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>taxable parcels:</strong> {section.taxable_parcels_count}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>improved parcels:</strong> {section.improved_parcels_count}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>number of parcels:</strong> {section.parcels_count}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleOpenModal('manageSection')}
                                            startIcon={<IconComponent iconType="section" />}
                                            sx={{ 
                                                mt: 2, 
                                                textTransform: 'none', 
                                                justifyContent: 'flex-start', // Align text to the left
                                                gap: 1 // Adjust space between icon and text
                                            }}
                                        >
                                            Manage this Section
                                        </Button>
                                    </Box>
                                )}
                            </Collapse>
                        </Box>
                        <Box mb={1}>
                            <Button
                                variant="text"
                                color="primary"
                                endIcon={openSections.parcel ? <ExpandLess /> : <ExpandMore />}
                                onClick={() => handleToggleCollapse('parcel')}
                                fullWidth
                                sx={{ 
                                    textTransform: 'none', 
                                    justifyContent: 'flex-start',
                                    gap: 1, 
                                    fontWeight: 'bold',
                                    fontSize: '1rem'
                                }}
                            >
                                <IconComponent iconType="parcel" /> Parcel
                            </Button>
                            <Collapse in={openSections.parcel}>
                                {parcel && (
                                    <Box mt={0} p={1} border={0} pl={0}>
                                        <Typography variant="body2">
                                            <strong>PIN:</strong> {parcel.parcel_pin}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>plan #:</strong> {parcel.plan_number}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>area:</strong> {parcel.area} sq. m.
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>taxable:</strong> {parcel.taxable}
                                        </Typography>
                                        <Typography variant="body2">
                                            <strong>improved:</strong> {parcel.improved}
                                        </Typography>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => handleOpenModal('manageParcel')}
                                            startIcon={<IconComponent iconType="parcel" />}
                                            sx={{ 
                                                mt: 2, 
                                                textTransform: 'none', 
                                                justifyContent: 'flex-start', // Align text to the left
                                                gap: 1 // Adjust space between icon and text
                                            }}
                                        >
                                            Manage this Parcel
                                        </Button>
                                    </Box>
                                )}
                            </Collapse>
                        </Box>
                    </Box>
                </Box>
            </Fade>
            {isLoading && (
                <Box
                    position="absolute"
                    top={0}
                    left={0}
                    width="100%"
                    height="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    bgcolor="background.paper"
                    zIndex={1000}
                >
                    <CircularProgress />
                </Box>
            )}
            <ATMSSModal
                show={showModal}
                onCloseText={modalContent.onCloseText}
                onAcceptText={modalContent.onAcceptText}
                onClose={handleCloseModal}
                onAccept={handleAcceptModal}
                title={modalContent.title}
            >
                <Typography variant="body1">
                    {renderMessageWithLineBreaks(modalContent.message)}
                </Typography>
            </ATMSSModal>
        </Box>
    );
};

export default MainInfo;
