import * as L from 'leaflet'; // Import Leaflet library
/**
 * Converts coordinates from [lng, lat] to [lat, lng] format and returns an array of LatLng objects.
 * @param {GeoJSON} geojsonLayer - The GeoJSON layer to extract LatLng coordinates from. (e.g. GeoJSON straight from API)
 * @returns {Array<<L.LatLng>>} An array containing LatLng coordinates of each feature in the layer.
 */
export const getLatLngs = (geojsonLayer) => {
    const coordsArray = geojsonLayer.geometry.coordinates[0]; // TODO test this later

    if (Array.isArray(coordsArray)) {
        const latLngs = [];
        for (const coords of coordsArray) {
            const latLng = [];
            for (const coord of coords) {
                // Swap lng and lat to create LatLng object
                latLng.push(L.latLng(coord[1], coord[0]));
            }
            latLngs.push(latLng);
        }
        return latLngs;
    }
    else {
        return [];
    }
};
