import React, { useContext } from 'react';

import { MapContext } from '../../../context/MapContext';

import CreateTools from './CreateTools';
import EditTools from '../../bootstrap/parcels_manager/EditTools';

import { Box, Typography } from '@mui/material';

const GeoTools = () => {
    const { enableCreateTools, enableEditTools } = useContext(MapContext);

    return (
        <Box p={2} width="100%" className="flex-shrink-0">
            <Typography variant="h6" fontWeight="bold">
                GeoTools
            </Typography>

            <Box borderTop={1} borderColor="divider" my={2} />

            {!enableCreateTools && !enableEditTools && (
                <Typography variant="body2">
                    The "ATMSS GeoTools" is a specialized software suite for managing, analyzing, and visualizing cadastral data within land administration.
                    <br /><br />
                    To get started, please utilize the
                    <Typography component="span" variant="body2">
                        <strong> Cadastral Data Fabric </strong>
                    </Typography>
                    on the right panel to select the appropriate tools and actions.
                </Typography>
            )}

            {enableCreateTools && (
                <CreateTools />
            )}

            {enableEditTools && (
                <EditTools />
            )}

        </Box>
    );
};

export default GeoTools;
