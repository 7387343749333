import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

const IconComponent = ({ iconType }) => {
  const theme = useTheme();

  const iconStyles = {
    width: '1em',
    height: '1em',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    filter: theme.palette.mode === 'dark' ? 'brightness(1.5) invert(1)' : 'brightness(0.5)',
  };

  const icons = {
    district: '/images/district.svg',
    barangay: '/images/barangay.svg',
    section: '/images/section.svg',
    parcel: '/images/parcel.svg',
  };

  return <Box sx={{ ...iconStyles, backgroundImage: `url(${icons[iconType]})` }} />;
};

export default IconComponent;
