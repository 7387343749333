/**
 * Parses a bounding box string and returns the center coordinates and bounds.
 * @param {string} bboxString - The bounding box string in the format 'BOX(minLon minLat,maxLon maxLat)'.
 * @returns {object} - An object containing the center coordinates and bounds.
 */
export const parseBBox = (bboxString) => {
    const bboxRegex = /BOX\(([^ ]+) ([^,]+),([^ ]+) ([^)]+)\)/;
    const matches = bboxString.match(bboxRegex);

    if (matches && matches.length === 5) {
        const minLon = parseFloat(matches[1]);
        const minLat = parseFloat(matches[2]);
        const maxLon = parseFloat(matches[3]);
        const maxLat = parseFloat(matches[4]);

        // Calculate the center of the bounding box
        const centerLat = (minLat + maxLat) / 2;
        const centerLon = (minLon + maxLon) / 2;

        // Calculate the bounds
        const bounds = [
            [minLat, minLon],
            [maxLat, maxLon]
        ];

        return {
            center: [centerLat, centerLon],
            bounds
        };
    }

    throw new Error('Invalid bounding box format');
};
