import React, { useState, createContext } from 'react';
import ParcelFinder from '../apis/DataFinder';

// context for managing parcels
export const ParcelsContext = createContext();
export const ParcelsContextProvider = (props) => {
    const [parcels, setParcels] = useState([]);
    const [parcel, setParcel] = useState(null);
    const [neighbors, setNeighbors] = useState([]);
    const [showNeighbors, setShowNeighbors] = useState(false);
    const [neighborLayerGroup, setNeighborLayerGroup] = useState(null);

    // Fetch Parcels from Section
    const fetchParcelsFromSection = async (sectionId) => {
        try {
            setParcels([]);
            if (sectionId !== 'default' && sectionId !== undefined) { // Check if sectionId is not 'default' and 'undefined'
                // Make API call to fetch Parcels from Section with sectionId
                const response = await ParcelFinder.get(`/sections/${sectionId}/parcels`);
                setParcels(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch all Parcels from Section.', error);
            setParcels([]);
        }
    };

    // Fetch Parcel
    const fetchParcel = async (parcelId) => {
        try {
            setParcel(null);
            if (parcelId !== 'default' && parcelId !== undefined) { // Check if parcelId is not 'default' and 'undefined'
                // Make API call to fetch Parcel by parcelId
                const response = await ParcelFinder.get(`/parcels/${parcelId}`);
                setParcel(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch Parcel.', error);
            setParcel(null);
        }
    };

    const fetchNeighbors = async (parcelId, distance) => {
        try {
            setNeighbors([]);
            if (parcelId !== 'default' && parcelId !== undefined) { // Check if parcelId is not 'default' and 'undefined'
                // Make API call to fetch Parcel by parcelId
                const response = await ParcelFinder.get(`/parcels/${parcelId}/neighbors/${distance}`);
                setNeighbors(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch Neighbors.', error);
            setNeighbors([]);
        }
    };

    return (
        <ParcelsContext.Provider value={{
            parcels, parcel, neighbors, showNeighbors, neighborLayerGroup,
            setParcels, setParcel, setNeighbors, setShowNeighbors, 
            setNeighborLayerGroup, fetchParcelsFromSection, fetchNeighbors, fetchParcel
        }}>
            {props.children}
        </ParcelsContext.Provider>
    )
}