import React, { useState, useContext, useEffect } from 'react';
import { Button, Container, Typography, Box, IconButton, Collapse } from '@mui/material';
import { Close as CloseIcon, Map as MapIcon, MapOutlined as MapOutlinedIcon, DocumentScanner as DocumentScannerIcon, TableView as TableViewIcon } from '@mui/icons-material';

import '../../../styles.css';
import renderMessageWithLineBreaks from '../../../utils/renderMessageWithLineBreaks';
import { parseBBox } from '../../../utils/parseBBox';

import { ParcelsContext } from '../../../context/ParcelsContext';
import { TaxParcelsContext } from '../../../context/TaxParcelsContext';
import { MapContext } from '../../../context/MapContext';

import SourceReference from './SourceReference';
import AttributeForm from './AttributeForm';

import ATMSSModal from '../common/ATMSSModal';

const CreateTools = () => {
    const { parcel } = useContext(ParcelsContext);
    const { attributeData, postTaxParcel } = useContext(TaxParcelsContext);
    const {
        map, drawnLayer, setDrawnLayer, setEnableCreateTools, source,
        setParcelCreateMode, setMapCreateMode, sourceIsPosted, attributeIsPosted, setIsCommitted
    } = useContext(MapContext);

    const [modeType, setModeType] = useState(null);
    const [nextModeType, setNextModeType] = useState(null);
    const [activeModeType, setActiveModeType] = useState(null);

    const [isVisible, setIsVisible] = useState(true);
    const [isReferenceVisible, setIsReferenceVisible] = useState(true);
    const [isAttributeVisible, setIsAttributeVisible] = useState(true);

    const [showModal, setShowModal] = useState(false);
    const [modalContent, setModalContent] = useState({
        singleButton: 'enabled',
        onCloseText: 'Close',
        onAcceptText: 'Accept',
        title: 'Default Title',
        message: 'Default message content.',
    });

    const handleSelectToolType = (newModeType) => {
        setIsVisible(false);
        if (!modeType) {
            if (newModeType === 'Auto') handleParcelCreate();
            if (newModeType === 'Manual') handleMapCreate();
            setModeType(newModeType);
        } else if (modeType !== newModeType) {
            setModalContent({
                onCloseText: 'Cancel',
                onAcceptText: 'Accept',
                title: `Warning: Changing the Create Mode to ${newModeType}`,
                message: `Are you sure you want to change to this mode? \nCurrent inputs may be lost from this action.`,
            });
            setNextModeType(newModeType);
            setShowModal(true);
        }

        if (modeType === 'Auto' && newModeType === 'Auto') {
            setModalContent({
                singleButton: 'enabled',
                onAcceptText: 'OK',
                title: `Information: Plotting by the Parcel's geometry`,
                message: `This will extract the Geometry of the base Cadastre. \nNo digitizing required.`,
            });
            setShowModal(true);
        }

        if (modeType === 'Manual' && newModeType === 'Manual') {
            setModalContent({
                singleButton: 'enabled',
                onAcceptText: 'OK',
                title: 'Information: Plotting by digitizing the map',
                message: `Digitize the Polygon. \nPlease use the Draw and Edit Tool on the left side of the map.`,
            });
            setShowModal(true);
        }
    };

    const handleCancelTool = () => {
        setModalContent({
            onCloseText: 'Back',
            onAcceptText: 'Confirm',
            title: 'Warning: Cancel Tax Parcel Creation',
            message: 'Are you sure you want to cancel? \nCurrent inputs may be lost from this action.',
        });
        setNextModeType('Cancel');
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNextModeType(null);
    };

    const handleAcceptModal = () => {
        if (nextModeType === 'Auto') handleParcelCreate();
        if (nextModeType === 'Manual') handleMapCreate();
        if (nextModeType === 'Cancel') handleCancelCreate();
        setModeType(nextModeType);
        setShowModal(false);
        setNextModeType(null);
    };

    const handleParcelCreate = () => {
        setActiveModeType('Auto');

        flyToParcel();
        setMapCreateMode(false);
        setDrawnLayer(null);
        setParcelCreateMode(true);
        setModalContent({
            singleButton: 'enabled',
            onAcceptText: 'OK',
            title: `Information: Plotting by the Parcel's geometry`,
            message: `This will extract the Geometry of the base Parcel. \nPlease use the Edit Tools if necessary.`,
        });
        setShowModal(true);
    };

    const handleMapCreate = () => {
        setActiveModeType('Manual');

        flyToParcel();
        setParcelCreateMode(false);
        setDrawnLayer(null);
        setMapCreateMode(true);
        setModalContent({
            singleButton: 'enabled',
            onAcceptText: 'OK',
            title: 'Information: Plotting by digitizing the map',
            message: `Digitize a polygon from the map. \nPlease use the Draw and Edit Tools on the left side of the map.`,
        });
        setShowModal(true);
    };

    const handleCancelCreate = () => {
        setEnableCreateTools(false);
        setParcelCreateMode(false);
        setMapCreateMode(false);
        setDrawnLayer(null);
    };

    const flyToParcel = () => {
        if (parcel) {
            const { bounds } = parseBBox(JSON.stringify(parcel.bbox));
            map.flyToBounds(bounds, {
                animate: true,
                duration: 1,
            });
        }
    };

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    const handleShowReference = () => {
        setIsReferenceVisible(true);
    };

    const handleCloseReference = () => {
        setIsReferenceVisible(false);
    };

    const handleShowAttribute = () => {
        setIsAttributeVisible(true);
    };

    const handleCloseAttribute = () => {
        setIsAttributeVisible(false);
    };

    const handleCommit = async() => {
        //Assemble the data
        const sourceId = source.source_id;
        const declaredArea = attributeData.declared_area;
        const tPNumber = attributeData.tax_parcel_number;
        const geometry = attributeData.geometry;

        const taxParcelData = {
            tax_parcel_number: tPNumber,
            declared_area: declaredArea,
            assessed_value: 0,
            market_value: 0,
            parcel_id: parcel.parcel_id,
            source_id: sourceId,
            person_id: 1,
            geometry
        };

        try {
            const response = await postTaxParcel(taxParcelData);
            alert(response);

            if (response) {
                setIsCommitted(true);
            }
        } catch (error) {
            console.log(`Unknown Error: ${error}`);
        }
    };

    useEffect(() => {
        return () => {
            map.off('pm:drawstart');
            map.off('pm:create');
        };
        // eslint-disable-next-line
    }, []);

    // This resets everything when drawnLayer is deleted
    useEffect(() => {
        if (!drawnLayer) {
            setParcelCreateMode(false);
            setMapCreateMode(false);
            setModeType(null);
        }

        console.log(sourceIsPosted);
        console.log(attributeIsPosted);
        // eslint-disable-next-line
    }, [drawnLayer]);

    return (
        <Container className="mt-2" disableGutters>
            <Box mb={2}>
                <Button
                    variant="outlined"
                    color="primary"
                    fullWidth
                    onClick={toggleVisibility}
                >
                    {isVisible ? 'Hide' : 'Show'} Information
                </Button>
            </Box>
            <Collapse in={isVisible}>
                <Box mb={2}>
                    <Typography variant="body2">
                        "New Tax Mapping" will define a new Polygon within
                        the <strong> Cadastral Data Fabric </strong> and store it in the database.
                        <br /><br />Choose the appropriate mode for your task.
                        <br /><br />Use the <strong> Commit </strong>
                        button to save when finished.
                    </Typography>
                </Box>
            </Collapse>

            <Box borderTop={1} borderColor="divider" my={0} />

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    // border: '1px solid',
                    // borderColor: theme => theme.palette.grey[400], // Use the same color as outlined button
                    borderRadius: 1,
                    padding: 1,
                    '&:hover': {
                        backgroundColor: 'rgba(0, 0, 0, 0.1)', // Change background on hover
                        cursor: 'pointer', // Change cursor to pointer on hover
                        borderColor: theme => theme.palette.primary.main, // Darken border on hover
                    },
                }}
            >
                <Typography
                    variant="body2"
                    sx={{
                        color: 'primary.main',
                        textTransform: 'uppercase', // Apply textTransform effect
                        '&:hover': { color: 'primary.dark' },
                    }}
                >
                    New Tax Mapping
                </Typography>
                <IconButton color="inherit" onClick={handleCancelTool}>
                    <CloseIcon />
                </IconButton>
            </Box>

            <Box mb={1}>
                <Button
                    variant={activeModeType === 'Auto' ? 'contained' : 'outlined'}
                    startIcon={<MapIcon />}
                    fullWidth
                    sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
                    onClick={() => handleSelectToolType('Auto')}
                >
                    Auto Geometry
                </Button>
            </Box>

            <Box mb={2}>
                <Button
                    variant={activeModeType === 'Manual' ? 'contained' : 'outlined'}
                    startIcon={<MapOutlinedIcon />}
                    fullWidth
                    sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
                    onClick={() => handleSelectToolType('Manual')}
                >
                    Manual Plotting
                </Button>
            </Box>

            <Box borderTop={1} borderColor="divider" my={1} />

            <Box mt={2} mb={1}>
                <Button
                    variant='outlined'
                    startIcon={<DocumentScannerIcon />}
                    fullWidth
                    sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
                    onClick={() => handleShowReference()}
                >
                    Show Reference
                </Button>
            </Box>
            {drawnLayer && (
                <Box mb={2}>
                    <Button
                        variant='outlined'
                        startIcon={<TableViewIcon />}
                        fullWidth
                        sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
                        onClick={() => handleShowAttribute()}
                    >
                        Show Attribute
                    </Button>
                </Box>
            )}

            <Box borderTop={1} borderColor="divider" my={1} />

            {drawnLayer && (
                <Box mt={2}>
                    <Button
                        variant='contained'
                        startIcon={<TableViewIcon />}
                        fullWidth
                        sx={{ textAlign: 'left', justifyContent: 'flex-start' }}
                        onClick={() => handleCommit()}
                        disabled={!sourceIsPosted && !attributeIsPosted}
                    >
                        Commit
                    </Button>
                </Box>
            )}

            {/* Modal */}
            <ATMSSModal
                show={showModal}
                singleButton={modalContent.singleButton}
                onCloseText={modalContent.onCloseText}
                onAcceptText={modalContent.onAcceptText}
                onClose={handleCloseModal}
                onAccept={handleAcceptModal}
                title={modalContent.title}
            >
                <Typography>
                    {renderMessageWithLineBreaks(modalContent.message)}
                </Typography>
            </ATMSSModal>

            {/* ShowReference Modal */}
            <SourceReference
                showModal={isReferenceVisible}
                onClose={handleCloseReference}
            />

            {drawnLayer && (
                <AttributeForm
                    showModal={isAttributeVisible}
                    onClose={handleCloseAttribute}
                />
            )}
        </Container>
    );
};

export default CreateTools;
