import React, { useRef }  from 'react';
import Draggable from 'react-draggable';

import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Paper } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

// Draggable Paper component to be used inside Dialog
const DraggablePaper = (props) => {
    const paperRef = useRef(null);

    return (
        <Draggable handle="#draggable-dialog-title" nodeRef={paperRef}>
            <Paper ref={paperRef} {...props} />
        </Draggable>
    );
};

const ATMSSModal = ({ show, singleButton, onCloseText, onAcceptText, onClose, onAccept, title, children }) => {
    const theme = useTheme(); // Access the theme

    return (
        <Dialog
            open={show}
            onClose={onClose}
            PaperComponent={DraggablePaper} // Make the Paper component draggable
            aria-labelledby="draggable-dialog-title"
            fullWidth
            maxWidth="sm"
            scroll="paper"
        >
            {title && (
                <DialogTitle style={{ cursor: 'move', position: 'relative' }} id="draggable-dialog-title">
                    {title}
                    <IconButton
                        onClick={onClose}
                        style={{ position: 'absolute', right: 8, top: 8 }}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            )}

            <DialogContent dividers>
                {children}
            </DialogContent>
            <DialogActions>
                {singleButton ? (
                    <Button
                        onClick={onClose}
                        variant="contained"
                        color="primary"
                        sx={{
                            mt: 2,
                            mb: 2,
                            borderRadius: '20px',
                            textTransform: 'none',
                            boxShadow: 3,
                            '&:hover': {
                                boxShadow: 6,
                                backgroundColor: theme.palette.primary.dark,
                                transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                            },
                        }}
                    >
                        {onAcceptText}
                    </Button>
                ) : (
                    <>
                        <Button
                            onClick={onClose}
                            variant="outlined"
                            sx={{
                                mt: 2,
                                mb: 2,
                                borderRadius: '20px',
                                textTransform: 'none',
                                borderColor: theme.palette.error.light, // Use theme color
                                color: theme.palette.error.light, // Use theme color
                                '&:hover': {
                                    borderColor: theme.palette.error.dark, // Use theme color
                                    backgroundColor: theme.palette.error.light, // Use theme color
                                    color: theme.palette.primary.contrastText,
                                    transition: 'background-color 0.3s ease, border-color 0.3s ease',
                                },
                            }}
                        >
                            {onCloseText}
                        </Button>
                        <Button
                            onClick={onAccept}
                            variant="contained"
                            color="primary"
                            sx={{
                                mt: 2,
                                mb: 2,
                                borderRadius: '20px',
                                textTransform: 'none',
                                boxShadow: 3,
                                '&:hover': {
                                    boxShadow: 6,
                                    backgroundColor: theme.palette.primary.dark,
                                    transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
                                },
                            }}
                        >
                            {onAcceptText}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ATMSSModal;
