import * as L from 'leaflet'; // Import Leaflet library
/**
 * Extracts outer boundaries and inner holes from a GeoJSON layer representing MultiPolygon geometries.
 * @param {GeoJSON} geojsonLayer - The GeoJSON object containing MultiPolygon geometries.
 * @returns {{outerBoundaries: Array<L.LatLng>, innerHoles: Array<L.LatLng>}} An object containing outer boundaries and inner holes.
 *                  The outerBoundaries property holds an array of LatLng objects representing outer ring coordinates.
 *                  The innerHoles property holds an array of LatLng objects representing inner ring coordinates (holes).
 */
export const extractPolygonsWithHoles = (geojsonLayer) => {
    // Initialize arrays to store outer boundaries and inner holes
    const outerBoundaries = [];
    const innerHoles = [];

    // Extract coordinates from MultiPolygon geometry
    const coordinates = geojsonLayer.geometry.coordinates;

    // Iterate over each polygon in the MultiPolygon
    coordinates.forEach(polygon => {
        // Extract outer ring coordinates
        const outerRingCoords = polygon[0].map(coords => L.latLng(coords[1], coords[0]));
        outerBoundaries.push(outerRingCoords);

        // If there are inner rings (holes), add them to the innerHoles array
        if (polygon.length > 1) {
            const holes = polygon.slice(1).map(hole => hole.map(coords => L.latLng(coords[1], coords[0])));
            innerHoles.push(...holes);
        }
    });

    // Return an object containing outer boundaries and inner holes
    return { outerBoundaries, innerHoles };
};

// Example usage:
// const { outerBoundaries, innerHoles } = extractPolygonsWithHoles(geojsonLayer);
// console.log(outerBoundaries);
// console.log(innerHoles);
