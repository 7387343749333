import React, { useState, createContext } from 'react';
import TaxParcelFinder from '../apis/DataFinder';

// context for managing TaxParcels
export const TaxParcelsContext = createContext();
export const TaxParcelsContextProvider = (props) => {
    const [taxParcels, setTaxParcels] = useState([]);
    const [taxParcel, setTaxParcel] = useState(null);
    const [lastTaxParcelNumber, setLastTaxParcelNumber] = useState(null);
    const [showTaxParcels, setShowTaxParcels] = useState(false);
    const [taxParcelLayerGroup, setTaxParcelLayerGroup] = useState(null);
    const [attributeData, setAttributeData] = useState({});

    // Fetch TaxParcels from Parcel
    const fetchTaxParcelsFromParcel = async (parcelID) => {
        try {
            setTaxParcels([]);
            if (parcelID !== 'default' && parcelID !== undefined) { // Check if parcelId is not 'default' and 'undefined'
                // Make API call to fetch TaxParcels from Parcel with parcelId
                const response = await TaxParcelFinder.get(`/parcels/${parcelID}/tax-parcels`);
                setTaxParcels(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch TaxParcels from Parcel.', error);
            setTaxParcels([]);
        }
    };

    // Fetch TaxParcel
    const fetchTaxParcel = async (taxParcelId) => {
        try {
            setTaxParcel(null);
            if (taxParcelId !== 'default' && taxParcelId !== undefined) { // Check if taxParcelId is not 'default' and 'undefined'
                // Make API call to fetch TaxParcel by taxParcelId
                const response = await TaxParcelFinder.get(`/tax-parcels/${taxParcelId}`);
                setTaxParcel(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch Tax Parcel.', error);
            setTaxParcel(null);
        }
    };
    
    // Fetch last taxparcel number
    const fetchLastTaxParcelNumber = async (sectionId) => {
        try {
            if (sectionId !== 'default' && sectionId !== undefined) { // Check if sectionId is not 'default' and 'undefined'
                // Make API call to fetch the last tax parcel number by sectionId
                const response = await TaxParcelFinder.get(`/sections/${sectionId}/last-tax-parcel-number`);
                setLastTaxParcelNumber(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch last tax parcel number.', error);
            setLastTaxParcelNumber(null);
        }
    };

    // Post TaxParcel
    const postTaxParcel = async (taxParcelData) => {
        try {
            const response = await TaxParcelFinder.post('/tax-parcels', taxParcelData);

            if (response.data){      
                const {tax_parcel_id} = response.data;
                await fetchTaxParcel(tax_parcel_id);
                return (`Successfully created a new tax parcel ${tax_parcel_id}.`);
            } 
        } catch (error) { 
            alert(`Error creating new tax parcel: ${error}`);
            setTaxParcel(null);
        }
    }

    //Delete TaxParcel
    const deleteTaxParcel = async (taxParcelId) => {
        try {
            if (taxParcelId !== 'default' && taxParcelId !== undefined) { // Check if taxParcelId is not 'default' and 'undefined'
                // Make API call to delete TaxParcel by taxParcelId
                const response = await TaxParcelFinder.delete(`/tax-parcels/${taxParcelId}`);
                setTaxParcel(null);
                return(response.data);
            }
        } catch (error) {
            console.error('Failed to delete Tax Parcel.', error);
        }
    };

    return (
        <TaxParcelsContext.Provider value={{
            taxParcels, taxParcel, lastTaxParcelNumber, showTaxParcels, taxParcelLayerGroup, attributeData,
            setTaxParcels, setTaxParcel, setLastTaxParcelNumber, setShowTaxParcels, setTaxParcelLayerGroup, 
            fetchTaxParcelsFromParcel, fetchTaxParcel, fetchLastTaxParcelNumber, postTaxParcel, deleteTaxParcel, setAttributeData
        }}>
            {props.children}
        </TaxParcelsContext.Provider>
    )
}