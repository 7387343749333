import React, { useState, createContext } from 'react';
import SectionFinder from '../apis/DataFinder';

// context for managing sections
export const SectionsContext = createContext();
export const SectionsContextProvider = (props) => {
    const [sections, setSections] = useState([]);
    const [section, setSection] = useState(null);
    const [sectionLayer, setSectionLayer] = useState(null);

    // Fetch Sections from Barangay
    const fetchSectionsFromBarangay = async (barangayId) => {
        try {
            // Reset
            setSections([]);
            if (barangayId !== 'default' && barangayId !== undefined) { // Check if barangayId is not 'default' and 'undefined'
                // Make API call to fetch Sections from Barangay with barangayId
                const response = await SectionFinder.get(`/barangays/${barangayId}/sections`);
                setSections(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch all Sections from Barangay.', error);
            setSections([]);
        }
    };

    // Fetch Section
    const fetchSection = async (sectionId) => {
        try {
            // Reset
            setSection(null);
            if (sectionId !== 'default' && sectionId !== undefined) { // Check if sectionId is not 'default' and 'undefined'
                // Make API call to fetch Section by sectionId
                const response = await SectionFinder.get(`/sections/${sectionId}`);
                setSection(response.data);
            }
        } catch (error) {
            console.error('Failed to fetch Barangay.', error);
            setSection(null);
        }
    };

    return (
        <SectionsContext.Provider value={{
            sections, section, sectionLayer,
            setSections, setSection, setSectionLayer, fetchSectionsFromBarangay, fetchSection
        }}>
            {props.children}
        </SectionsContext.Provider>
    )
}