import React, { useRef, useEffect, useContext } from 'react';

import { Card, CardContent, Typography } from '@mui/material';

import * as L from 'leaflet'; // Import Leaflet library
import 'leaflet/dist/leaflet.css';
import '../../../styles.css';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-imageoverlay-rotated'

import { parseBBox } from '../../../utils/parseBBox';
import { getLatLngs } from '../../../utils/getLatLngs';
import { digitizePolygon } from '../../../utils/digitizePolygon';
import { isPointInPolygon } from '../../../utils/isPointInPolygon';
import { extractPolygonsWithHoles } from '../../../utils/extractPolygonsWithHoles';
import { splitPolygon } from '../../../utils/splitPolygon';

// Import Contexts
import { DistrictsContext } from '../../../context/DistrictsContext';
import { BarangaysContext } from '../../../context/BarangaysContext';
import { SectionsContext } from '../../../context/SectionsContext';
import { ParcelsContext } from '../../../context/ParcelsContext';
import { TaxParcelsContext } from '../../../context/TaxParcelsContext';
import { MapContext } from '../../../context/MapContext';

import LeafletStyles from '../../../assets/LeafletStyles';

const MapViewer = () => {
    const { district } = useContext(DistrictsContext);
    const { barangay } = useContext(BarangaysContext);
    const { section, setSectionLayer } = useContext(SectionsContext);
    const { parcel, neighbors, showNeighbors, setNeighborLayerGroup } = useContext(ParcelsContext);
    const { taxParcel, taxParcels, lastTaxParcelNumber, showTaxParcels, setTaxParcelLayerGroup } = useContext(TaxParcelsContext);
    const {
        setMap, setMapMessage, userInitiatedChange, isCommitted, setIsCommitted, setDrawnLayer, setInfoControl,
        enableCreateTools, setEnableCreateTools, parcelCreateMode, setParcelCreateMode, tdCreateMode, setTdCreateMode, mapCreateMode, setMapCreateMode,
        enableEditTools, setEnableEditTools, partitionEditMode, setPartitionEditMode,
        isPartitioned, setIsPartitioned, showPartitions, setShowPartitions, partitionLayerGroup, setPartitionLayerGroup, isUploaded
    } = useContext(MapContext);

    // Ref to hold the map instance
    const mapRef = useRef(null);
    // A reference to the Layer Control
    const layerControlRef = useRef(null);
    // A reference to the Info Control
    const infoControlRef = useRef(null);

    // Individual layer
    const districtLayerRef = useRef(null); // A reference to the District GeoJSONLayer
    const barangayLayerRef = useRef(null); // A reference to the Barangay GeoJSONLayer
    const sectionLayerRef = useRef(null); // A reference to the Section GeoJSONLayer
    const parcelLayerRef = useRef(null); // A reference to the Parcel GeoJSONLayer
    const drawnLayerRef = useRef(null); // A reference to the drawing
    const scannedLayerRef = useRef(null); // A reference to the uploaded scan
    const markerRefs = useRef([]);

    // Layer Groups for overlays
    const neighborLayerGroupRef = useRef(null);
    const taxParcelLayerGroupRef = useRef(null);
    const drawnLayerGroupRef = useRef(null);

    const startDrawingMode = (event) => {
        console.log("drawing");
        const workingLayer = event.workingLayer;

        // Event listener for vertex added
        workingLayer.on('pm:vertexadded', (e) => {
            const corners = e.target.getLatLngs();
            const index = e.target.getLatLngs().length - 1;

            // Check if any corner is inside the existing parcel
            if (!isPointInPolygon(corners[index], parcel)) {
                // If outside remove the last corner
                alert(`Corners of this Tax Parcel must be within Parcel: ${parcel.parcel_pin}`);
                corners.splice(index, 1);
                e.target.setLatLngs(corners);
            }
        });
    };

    const createDrawingLayer = (event) => {
        const map = mapRef.current;
        console.log("create");
        // The layer is in event.layer || map is in event.target
        const layer = event.layer;
        // Remove previous 
        if (drawnLayerRef.current) {
            map.removeLayer(drawnLayerRef.current);
            drawnLayerRef.current = null;
            setDrawnLayer(null);
        }

        if (layer) {
            // Add event listeners 
            initializeMapEventHandlers(layer);

            map.pm.addControls({
                drawPolygon: false
            });

            layer.addTo(map);

            // This segment will enable the Attribute Form component
            drawnLayerRef.current = layer;
            setDrawnLayer(layer.toGeoJSON());
        }
    };

    const editDrawingLayer = (event) => {
        const map = mapRef.current;
        console.log("edit");
        const layer = event.layer;
        layer.setStyle({ color: '#3388ff' });

        // Remove previous 
        if (drawnLayerRef.current === layer) {
            map.removeLayer(drawnLayerRef.current);
            drawnLayerRef.current = null;
            setDrawnLayer(null);
        }

        if (layer.cutted) {
            map.removeLayer(layer);
        }

        else {
            layer.addTo(map);
            drawnLayerRef.current = layer;
            setDrawnLayer(layer.toGeoJSON());
        }
    };

    const cutDrawingLayer = (event) => {
        const map = mapRef.current;
        console.log("cut");
        event.originalLayer.cutted = true;

        const layer = event.layer;
        layer.setStyle({ color: '#3388ff' });

        if (drawnLayerRef.current) {
            map.removeLayer(drawnLayerRef.current);
            drawnLayerRef.current = null;
            setDrawnLayer(null);
        }

        // Add event listeners 
        initializeMapEventHandlers(layer);

        layer.addTo(map);
        drawnLayerRef.current = layer;
        setDrawnLayer(layer.toGeoJSON());
    };

    const removeDrawingLayer = () => {
        const map = mapRef.current;
        console.log("remove");
        if (drawnLayerRef.current) {
            map.removeLayer(drawnLayerRef.current);
            drawnLayerRef.current = null;
            setDrawnLayer(null);
            alert('You deleted the drawing, please re-digitize');
            map.pm.addControls({
                drawPolygon: true,
            });
        }
    };

    const dragDrawingLayer = (layer) => {
        console.log("drag");
        let prevLatLngs = [];
        let isPointOutside = false;

        layer.on('pm:markerdragstart', (e) => {
            prevLatLngs = e.target.getLatLngs();
        });

        layer.on('pm:markerdragend', (e) => {
            if (isPointOutside) {
                // Reset it to its previous valid geometry
                e.target.setLatLngs(prevLatLngs);
                e.layer.setStyle({ color: '#3388ff' });
            }
        });

        layer.on('pm:markerdrag', (e) => {
            const point = e.markerEvent.latlng;

            isPointOutside = !isPointInPolygon(point, parcel);
            if (isPointOutside) {
                e.layer.setStyle({ color: 'red' });
            }
        });
    };

    const initializeMapEventHandlers = (layer) => {
        // Add event listeners for editing, cutting, and removing the layer
        layer.on('pm:edit', (e) => editDrawingLayer(e));
        layer.on('pm:cut', (e) => cutDrawingLayer(e));
        layer.on('pm:remove', () => removeDrawingLayer());

        // Add event listeners for drag events
        dragDrawingLayer(layer);
    };

    const startEditingMode = (taxParcel) => {
        console.log("start edit mode");

        const map = mapRef.current;

        const drawnLayerGroup = L.layerGroup();

        const { outerBoundaries, innerHoles } = extractPolygonsWithHoles(taxParcel);
        const id = taxParcel.tax_parcel_pin;

        // Create a Leaflet polygon with holes
        const layer = L.polygon([outerBoundaries[0], ...innerHoles], {
            id
        });
        layer.setStyle({ color: '#3388ff' });

        drawnLayerGroup.addLayer(layer);
        drawnLayerGroup.addTo(map);

        drawnLayerGroupRef.current = drawnLayerGroup;

        setDrawnLayer(layer.toGeoJSON());
    };

    const partitionDrawingLayer = () => {
        console.log("start edit mode");
        const map = mapRef.current;

        map.on('pm:create', createPartitionedParcels);
    };

    const createPartitionedParcels = (event) => {
        const map = mapRef.current;
        const line = event.layer;

        const drawnlayerGroup = drawnLayerGroupRef.current;
        let partitions = Object.values(drawnlayerGroup.getLayers()); // array of drawnLayers

        // Remove from map the old layers
        partitions.forEach(partition => map.removeLayer(partition));

        const newPartitions = [];
        for (const partition of partitions) {
            // Split each partition with the current line
            // TODO There is an uncaught runtime exception here - linearing polygon
            //const splitResult = splitPolygon(partition, line, map);
            const splitResult = splitPolygon(partition, line);

            if (splitResult) {
                newPartitions.push(...splitResult);
            }
            // partitions that are not split will be added to the newPartitions
            else {
                newPartitions.push(partition);
            }
        }
        partitions = newPartitions;

        const newdrawnLayerGroup = L.layerGroup();

        const partitionIds = [];
        // Add to map the new layer
        partitions.forEach(partition => {
            newdrawnLayerGroup.addLayer(partition);
            const partitionId = partition.options.id;
            partitionIds.push(partitionId);
        });

        //Overwrite the old ref
        drawnLayerGroupRef.current = newdrawnLayerGroup;

        map.removeLayer(line);

        // This will trigger the Fabric to display partitioned layers
        if (partitions.length > 1) {
            const type = 'partitions';
            const content = {
                partitionIds
            };

            const message = {
                type,
                content
            };

            setIsPartitioned(true);
            setShowPartitions(true);
            setMapMessage(message);
        }
    };

    // Function to highlight corresponding Leaflet layer
    const highlightLayer = (event) => {
        const layer = event.target;
        const info = infoControlRef.current;

        // Change the style to highlight
        if (layer) {
            const props = layer.toGeoJSON().properties;
            const type = layer.options.type;
            layer.setStyle(LeafletStyles.highlightLayer);
            if (info) {
                info.update(props, type);
            }
        }
    };

    const resetHighlightLayer = (event) => {
        const layer = event.target;
        const { type } = layer.options;

        // Change the style to original
        if (type === 'neighbor') {
            layer.setStyle(LeafletStyles.neighborLayer);
        }
        if (type === 'parcel') {
            layer.setStyle(LeafletStyles.parcelLayer);
        }
        if (type === 'taxParcel') {
            layer.setStyle(LeafletStyles.taxParcelLayer);
        }
        if (type === 'partition') {
            layer.setStyle(LeafletStyles.partitionLayer);
        }
    };

    // Function to handle layer click and fly to its bbox
    const flyToLayer = (event) => {
        const map = mapRef.current;
        const layer = event.target;
        const { bounds } = layer.options;
        map.flyToBounds(bounds, {
            animate: true,
            duration: 1 // in seconds
        });
    };

    // Handle edit mode and also reconstruct taxParcel
    const handleEditMode = (event) => {
        const tax_parcel_id = event.target.feature.properties.tax_parcel_id;

        const type = 'tools';
        const content = {
            toolType: 'editTaxParcel',
            sourceId: tax_parcel_id
        };

        const message = {
            type,
            content
        };

        setMapMessage(message);
    };

    // Function to reset layer and ref 
    const removeLayer = (layer, control, ref) => {
        if (layer) {
            layer.off();
            control.removeLayer(layer);
            control._map.removeLayer(layer);
            ref.current = null;
        }
    };

    // Function to turn off all events in the map
    const offAllLayers = () => {
        districtLayerRef.current.eachLayer(layer => {
            layer.off();
        });
        barangayLayerRef.current.eachLayer(layer => {
            layer.off();
        });
        sectionLayerRef.current.eachLayer(layer => {
            layer.off();
        });
        parcelLayerRef.current.eachLayer(layer => {
            layer.off();
        });
        if (neighborLayerGroupRef.current) {
            for (const neighborLayer of neighborLayerGroupRef.current.getLayers()) {
                neighborLayer.eachLayer(layer => {
                    layer.off();
                });
            }
        }
    };

    const onAllLayers = () => {
        districtLayerRef.current.eachLayer(layer => {
            layer.on('click', flyToLayer);
        });
        barangayLayerRef.current.eachLayer(layer => {
            layer.on('click', flyToLayer);
        });
        sectionLayerRef.current.eachLayer(layer => {
            layer.on('click', flyToLayer);
        });
        parcelLayerRef.current.eachLayer(layer => {
            layer.on('mouseover', highlightLayer);
            layer.on('mouseout', resetHighlightLayer);
            layer.on('click', flyToLayer);
        });
        if (neighborLayerGroupRef.current) {
            for (const neighborLayer of neighborLayerGroupRef.current.getLayers()) {
                neighborLayer.eachLayer(layer => {
                    layer.on('mouseover', highlightLayer);
                    layer.on('mouseout', resetHighlightLayer);
                    layer.on('click', flyToLayer);
                });
            }
        }
    };

    function updateInfo(props, type) {
        let headerText;
        let contents;

        // Define consistent inline styles
        const headerStyle = 'font-size: 1.25em; font-weight: bold; margin-bottom: 0.5em; color: #333;';
        const contentStyle = 'font-size: 1em; line-height: 1.5; color: #666;';

        // Set inner HTML content based on the type
        switch (type) {
            case 'taxParcel':
                headerText = 'Tax Parcels:';
                contents = props ? `
                Right-click to edit
                <br /><br /><b>${props.tax_parcel_pin}</b>
                <br />assessed value: ${props.assessed_value}
                <br />market value: ${props.market_value}` : '';
                break;
            case 'neighbor':
                headerText = 'Neighboring Parcels:';
                contents = props ? `
                <b>${props.pin}</b>
                <br />area: ${props.area} sq.m.
                <br />taxable: ${props.taxable}
                <br />improved: ${props.improved}` : '';
                break;
            case 'partition':
                headerText = 'Partitioned Parcels:';
                contents = props ? `
                <b>${props.tax_parcel_pin}</b>
                <br />proposed PIN: ${props.tax_parcel_number}
                <br />area: ${props.area} sq.m.` : '';
                break;
            default:
                headerText = 'Parcel:';
                contents = props ? `
                <b>${props.pin}</b>
                <br />area: ${props.area} sq.m.
                <br />taxable: ${props.taxable}
                <br />improved: ${props.improved}` : 'Hover over the parcels';
                break;
        }

        // Apply inline styles to header and contents
        this._div.innerHTML = `<h4 style="${headerStyle}">${headerText}</h4><div style="${contentStyle}">${contents}</div>`;
    };

    function addInfoControl() {
        this._div = L.DomUtil.create('div', 'info');
        updateInfo.call(this);
        return this._div;
    }

    // Rendering for map
    useEffect(() => {
        // Initialize map if it's not already initialized
        if (!mapRef.current) {
            // Create a map instance and specify its center and zoom level
            const map = L.map('map', {
                minZoom: 12,
                maxZoom: 22,
                pmIgnore: false
            }).setView([14.5826, 121.06481], 16);

            // Add a tile layer to the map
            const osm = L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
                minZoom: 12,
                maxZoom: 22,
                attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            });

            const mapBox = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=pk.eyJ1Ijoibm9ybWFuc2tpIiwiYSI6ImNrazZuYzNtZzA1bTEyb3FzY283bHoza3AifQ.UC4JqPIrVr4i2wYJvBoK3w', {
                id: 'mapbox/light-v9',
                tileSize: 512,
                zoomOffset: -1,
                minZoom: 12,
                maxZoom: 22,
                tms: false,
                crs: L.CRS.EPSG3123,
                attribution: 'Pasig City CLIMA Project 2023'
            });

            var googleSat = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
                maxZoom: 22,
                subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
            }).addTo(map);

            const baseMaps = {
                "OpenStreetMap": osm,
                "Map Box": mapBox,
                "Google Satellite": googleSat
            };

            const layerControl = L.control.layers(baseMaps, null);
            layerControl.addTo(map);

            // Assign the layer control to the ref
            layerControlRef.current = layerControl;

            // Assign the map instance to the ref
            mapRef.current = map;
            setMap(map); //TODO refactor need to delete this later

            // Initialize Leaflet-Geoman and add controls
            map.pm.addControls({
                position: 'topleft',
                drawMarker: false,
                drawCircleMarker: false,
                drawCircle: false,
                drawPolyline: false,
                drawPolygon: false,
                drawRectangle: false,
                drawText: false,
                editMode: false,
                dragMode: false,
                cutPolygon: false,
                removalMode: false,
                rotateMode: false
            });

            // Set options specifically for the Polygon drawing tool
            map.pm.Draw.Polygon.setOptions({
                snappable: true, // Enable snapping if needed
                snapDistance: 20, // Set the snapping distance if snappable is enabled
                allowSelfIntersection: false
            });
        }

        // eslint-disable-next-line
    }, []);

    // Rendering for a Parcel with every spatial data associated with it
    useEffect(() => {
        const map = mapRef.current;
        const layerControl = layerControlRef.current;

        if (parcel) {
            // Remove the previous districtLayer if it exists
            if (districtLayerRef.current) {
                removeLayer(districtLayerRef.current, layerControl, districtLayerRef);
            }
            // Remove the previous sectionLayer if it exists
            if (barangayLayerRef.current) {
                removeLayer(barangayLayerRef.current, layerControl, barangayLayerRef);
            }
            // Remove the previous sectionLayer if it exists
            if (sectionLayerRef.current) {
                removeLayer(sectionLayerRef.current, layerControl, sectionLayerRef);
            }
            // Remove the previous parcelLayer if it exists
            if (parcelLayerRef.current) {
                removeLayer(parcelLayerRef.current, layerControl, parcelLayerRef);
            }

            // BEGIN: Add the District layer
            const districtGeom =
            {
                "type": "Feature",
                "properties": {
                    "district_id": district.district_id
                },
                "geometry": district.geometry
            };

            const districtStyle = LeafletStyles.districtLayer;
            const districtBounds = parseBBox(JSON.stringify(district.bbox)).bounds;
            const districtLayer = L.geoJSON(districtGeom, {
                type: 'district',
                style: districtStyle,
                bounds: districtBounds,
                pmIgnore: true,
                onEachFeature: function (feature, layer) {
                    layer.on('click', (event) => flyToLayer(event));
                }
            }).addTo(map);

            layerControl.addOverlay(districtLayer, `District ${district.district_name}`);
            districtLayerRef.current = districtLayer;
            // END: Add the District layer

            // BEGIN: Add the Barangay layer
            const barangayGeom = {
                "type": "Feature",
                "properties": {
                    "barangay_id": barangay.barangay_id
                },
                "geometry": barangay.geometry
            };

            const barangayStyle = LeafletStyles.barangayLayer;
            const barangayBounds = parseBBox(JSON.stringify(barangay.bbox)).bounds;
            const barangayLayer = L.geoJSON(barangayGeom, {
                type: 'barangay',
                style: barangayStyle,
                bounds: barangayBounds,
                pmIgnore: true,
                onEachFeature: function (feature, layer) {
                    layer.on('click', (event) => flyToLayer(event));
                }
            }).addTo(map);

            layerControl.addOverlay(barangayLayer, `Barangay ${barangay.barangay_name}`);
            barangayLayerRef.current = barangayLayer;
            // END: Add the Barangay layer

            // BEGIN: Add the Section layer
            const sectionGeom = {
                "type": "Feature",
                "properties": {
                    "section_id": section.section_id,
                    "section_number": section.section_number,
                    "taxable_parcels_count": section.taxable_parcels_count,
                    "improved_parcels_count": section.improved_parcels_count,
                    "parcels_count": section.parcels_count
                },
                "geometry": section.geometry
            };

            const sectionStyle = LeafletStyles.sectionLayer;
            const sectionBounds = parseBBox(JSON.stringify(section.bbox)).bounds;
            const sectionLayer = L.geoJSON(sectionGeom, {
                type: 'section',
                style: sectionStyle,
                bounds: sectionBounds,
                pmIgnore: true,
                onEachFeature: function (feature, layer) {
                    layer.on('click', (event) => flyToLayer(event));
                }
            }).addTo(map);

            layerControl.addOverlay(sectionLayer, `Section ${section.section_number}`);
            sectionLayerRef.current = sectionLayer;
            // Save sectionLayer to the context
            setSectionLayer(sectionLayer);
            // END: Add the Section layer

            // BEGIN: Add the Parcel layer
            const parcelGeom = {
                "type": "Feature",
                "properties": {
                    "parcel_id": parcel.parcel_id,
                    "pin": parcel.parcel_pin,
                    "parcel_number": parcel.parcel_number,
                    "area": parcel.area,
                    "taxable": parcel.taxable,
                    "improved": parcel.improved
                },
                "geometry": parcel.geometry
            };

            const parcelStyle = LeafletStyles.parcelLayer;
            const parcelBounds = parseBBox(JSON.stringify(parcel.bbox)).bounds;
            const parcelLayer = L.geoJSON(parcelGeom, {
                type: 'parcel',
                style: parcelStyle,
                bounds: parcelBounds,
                pmIgnore: true,
                snapIgnore: false,
                onEachFeature: function (feature, layer) {
                    layer.on('mouseover', (event) => highlightLayer(event));
                    layer.on('mouseout', (event) => resetHighlightLayer(event));
                    layer.on('click', (event) => flyToLayer(event));
                }
            }).addTo(map);

            layerControl.addOverlay(parcelLayer, `Parcel ${parcel.parcel_number}`);

            // Save parcelLayer to the ref
            parcelLayerRef.current = parcelLayer;

            // Fly and zoom to bbox
            map.flyToBounds(parcelBounds, {
                animate: true,
                duration: 1 // in seconds
            });
            // END: Add the Parcel layer
        }
        // eslint-disable-next-line
    }, [parcel]);

    // Rendering for the Neighbors
    useEffect(() => {
        const map = mapRef.current;
        const layerControl = layerControlRef.current;

        if (neighborLayerGroupRef.current) {
            removeLayer(neighborLayerGroupRef.current, layerControl, neighborLayerGroupRef);
        }

        // Remove existing if all show Neighbors and show Tax and show Partitions are false
        if (infoControlRef.current && (!showNeighbors && !showTaxParcels && !showPartitions)) {
            infoControlRef.current.remove();
            infoControlRef.current = null;
        }

        // BEGIN: Add Neighbors layergroup
        // Check if there are neighbors surrouding the selected parcel
        if (neighbors.length !== 0 && showNeighbors) {
            // create a layergroup for the Neighbor layers
            const neighborLayerGroup = L.layerGroup();

            // Render all Neighbors of a Parcel and add to layerControl
            for (const neighbor of neighbors) {
                // Add the neighbor layer
                const neighborGeom = {
                    "type": "Feature",
                    "properties": {
                        "parcel_id": neighbor.parcel_id,
                        "pin": neighbor.parcel_pin,
                        "parcel_number": neighbor.parcel_number,
                        "area": neighbor.area,
                        "taxable": neighbor.taxable,
                        "improved": neighbor.improved
                    },
                    "geometry": neighbor.geometry
                };

                const neighborStyle = LeafletStyles.neighborLayer;
                const neighborBounds = parseBBox(JSON.stringify(neighbor.bbox)).bounds;
                const neighborLayer = L.geoJSON(neighborGeom, {
                    type: 'neighbor',
                    style: neighborStyle,
                    bounds: neighborBounds,
                    pmIgnore: true,
                    onEachFeature: function (feature, layer) {
                        layer.on('mouseover', (event) => highlightLayer(event));
                        layer.on('mouseout', (event) => resetHighlightLayer(event));
                        layer.on('click', (event) => flyToLayer(event));
                    }
                });

                neighborLayerGroup.addLayer(neighborLayer);
            }

            layerControl.addOverlay(neighborLayerGroup, "Neighbors");

            neighborLayerGroup.addTo(map);
            // Assign to the ref
            neighborLayerGroupRef.current = neighborLayerGroup;

            //Make the selected parcel still on the top
            if (parcelLayerRef.current) {
                parcelLayerRef.current.bringToFront();
            }
            //Make the tax parcels still on the top
            if (taxParcelLayerGroupRef.current) {
                // Bring all layers within the layer group to the front
                taxParcelLayerGroupRef.current.eachLayer(layer => {
                    layer.bringToFront();
                });
            }

            //If there are already drawnLayerGroups make them also on the top
            if (drawnLayerGroupRef.current) {
                const drawnLayers = Object.values(drawnLayerGroupRef.current.getLayers())
                drawnLayers.forEach(drawnLayer => drawnLayer.bringToFront());
            }

            //If there are already drawnLayers make them also on the top
            if (drawnLayerRef.current) {
                drawnLayerRef.current.bringToFront();
            }

            // If there are already partitioned layers make them also on the top
            if (partitionLayerGroup) {
                const partitions = Object.values(partitionLayerGroup.getLayers()); // array of drawnLayers
                partitions.forEach(partition => partition.bringToFront());
            }

            setNeighborLayerGroup(neighborLayerGroupRef.current);

            if (!infoControlRef.current) {
                // Create an info and save to reference
                const infoControl = L.control({
                    position: 'bottomleft' // Position the control in the lower left corner
                });

                infoControl.onAdd = addInfoControl;
                infoControl.update = updateInfo;

                infoControl.addTo(map);
                infoControlRef.current = infoControl;
                setInfoControl(infoControl);
            }

            // This will ensure that new layers will still be off when these modes are enabled
            if (enableCreateTools || enableEditTools) {
                offAllLayers();
            }
        } // END: Add Neighbors layergroup

        // eslint-disable-next-line
    }, [showNeighbors, neighbors]);

    // Rendering for tax parcels
    useEffect(() => {
        const map = mapRef.current;
        const layerControl = layerControlRef.current;

        if (taxParcelLayerGroupRef.current) {
            removeLayer(taxParcelLayerGroupRef.current, layerControl, taxParcelLayerGroupRef);
        }

        // Remove existing if all show Neighbors and show Tax and show Partitions are false
        if (infoControlRef.current && (!showNeighbors && !showTaxParcels && !showPartitions)) {
            infoControlRef.current.remove();
            infoControlRef.current = null;
        }

        // BEGIN: Add Tax Parcels layergroup
        // Check if there are Tax Parcels
        if (taxParcels.length !== 0 && showTaxParcels) {

            const taxParcelLayerGroup = L.layerGroup();

            // Render all Tax Parcels of a Parcel and add to layerControl
            for (const tParcel of taxParcels) {
                // Add the neighbor layer
                const taxParcelGeom = {
                    "type": "Feature",
                    "properties": {
                        "tax_parcel_id": tParcel.tax_parcel_id,
                        "tax_parcel_pin": tParcel.tax_parcel_pin,
                        "tax_parcel_number": tParcel.tax_parcel_number,
                        "assessed_value": tParcel.assessed_value,
                        "market_value": tParcel.market_value
                    },
                    "geometry": tParcel.geometry
                };

                const taxParcelStyle = LeafletStyles.taxParcelLayer;
                const taxParcelBounds = parseBBox(JSON.stringify(tParcel.bbox)).bounds;
                const taxParcelLayer = L.geoJSON(taxParcelGeom, {
                    type: 'taxParcel',
                    style: taxParcelStyle,
                    bounds: taxParcelBounds,
                    pmIgnore: true,
                    onEachFeature: function (feature, layer) {
                        layer.on('mouseover', (event) => highlightLayer(event));
                        layer.on('mouseout', (event) => resetHighlightLayer(event));
                        layer.on('click', (event) => flyToLayer(event));
                        layer.on('contextmenu', (event) => handleEditMode(event));
                    }
                });
                taxParcelLayerGroup.addLayer(taxParcelLayer);
            }

            layerControl.addOverlay(taxParcelLayerGroup, "Tax Parcels");

            taxParcelLayerGroup.addTo(map);

            //If there are already drawnLayerGroups make them also on the top
            if (drawnLayerGroupRef.current) {
                const drawnLayers = Object.values(drawnLayerGroupRef.current.getLayers())
                drawnLayers.forEach(drawnLayer => drawnLayer.bringToFront());
            }

            //If there are already drawnLayers make them also on the top
            if (drawnLayerRef.current) {
                drawnLayerRef.current.bringToFront();
            }

            // If there are already partitioned layers make them also on the top
            if (partitionLayerGroup) {
                const partitions = Object.values(partitionLayerGroup.getLayers()); // array of drawnLayers
                partitions.forEach(partition => partition.bringToFront());
            }

            // Assign to the ref
            taxParcelLayerGroupRef.current = taxParcelLayerGroup;

            setTaxParcelLayerGroup(taxParcelLayerGroupRef.current);

            if (!infoControlRef.current) {
                // Create an info and save to reference
                const infoControl = L.control({
                    position: 'bottomleft' // Position the control in the lower left corner
                });

                infoControl.onAdd = addInfoControl;
                infoControl.update = updateInfo;

                infoControl.addTo(map);
                infoControlRef.current = infoControl;
                setInfoControl(infoControl);
            }
        } // END: Add Tax Parcels layergroup

        // eslint-disable-next-line
    }, [showTaxParcels, taxParcels]);

    // Rendering partitioned layers
    useEffect(() => {
        console.log("rendering partitions");
        if (isPartitioned) {
            const map = mapRef.current;

            // Remove existing if all show Neighbors and show Tax and show Partitions are false
            if (infoControlRef.current && (!showNeighbors && !showTaxParcels && !showPartitions)) {
                infoControlRef.current.remove();
                infoControlRef.current = null;
            }

            // Remove previous from map
            if (partitionLayerGroup) {
                const partitions = Object.values(partitionLayerGroup.getLayers()); // array of drawnLayers
                partitions.forEach(partition => map.removeLayer(partition));
            }

            if (showPartitions) {
                const drawnlayerGroup = drawnLayerGroupRef.current;
                const partitions = Object.values(drawnlayerGroup.getLayers()); // array of drawnLayers

                const partLayerGroup = L.layerGroup();

                let lastTPNumber = Number(lastTaxParcelNumber);
                // Create a L.geoJSON layers stored is partitionLayerGroup
                partitions.forEach(partition => {
                    const geometry = partition.toGeoJSON().geometry;
                    const bounds = partition.getBounds();
                    const partitionBounds = [
                        [bounds.getSouth(), bounds.getWest()], // Southwest corner
                        [bounds.getNorth(), bounds.getEast()]  // Northeast corner
                    ];

                    const id = partition.options.id;
                    const sectionPIN = id.match(/^(\d{3}-\d{2}-\d{3}-\d{2})/)[0];
                    const tpNumber = ++lastTPNumber;
                    //const tax_parcel_number = taxParcel.tax_parcel_number; // TODO work on the numbering scheme
                    const tax_parcel_number = tpNumber.toString().padStart(3, '0');
                    const area = partition.options.area;

                    const partitionID = `${sectionPIN}-${tax_parcel_number}`;

                    const partitionGeom = {
                        "type": "Feature",
                        "properties": {
                            "partition_id": id,
                            "tax_parcel_pin": partitionID,
                            "tax_parcel_number": tax_parcel_number,
                            "area": area,
                            "assessed_value": taxParcel.assessed_value,
                            "market_value": taxParcel.market_value
                        },
                        "geometry": geometry
                    };

                    const partitionStyle = LeafletStyles.partitionLayer;
                    const partitionLayer = L.geoJSON(partitionGeom, {
                        type: 'partition',
                        style: partitionStyle,
                        bounds: partitionBounds,
                        pmIgnore: false
                    }).addTo(map);

                    partLayerGroup.addLayer(partitionLayer);
                });

                setPartitionLayerGroup(partLayerGroup);

                if (!infoControlRef.current) {
                    // Create an info and save to reference
                    const infoControl = L.control({
                        position: 'bottomleft' // Position the control in the lower left corner
                    });

                    infoControl.onAdd = addInfoControl;
                    infoControl.update = updateInfo;

                    infoControl.addTo(map);
                    infoControlRef.current = infoControl;
                    setInfoControl(infoControl);
                }
            }
        }
        // eslint-disable-next-line
    }, [showPartitions, isPartitioned, drawnLayerGroupRef.current]);

    // Rendering the Leaflet PM Tools
    useEffect(() => {
        if (userInitiatedChange) {
            const map = mapRef.current;

            if (enableCreateTools) {
                // Remove previous 
                if (drawnLayerRef.current) {
                    map.removeLayer(drawnLayerRef.current);
                    drawnLayerRef.current = null;
                    setDrawnLayer(null);
                }

                map.on('pm:drawstart', startDrawingMode);
                map.on('pm:create', createDrawingLayer);

                // Turn off all the layers events to avoid interfering with the drawing events
                offAllLayers();

                // Specific to parcelCreateMode
                if (parcelCreateMode) {
                    map.pm.addControls({
                        editMode: true,
                        cutPolygon: true,
                        removalMode: true,
                    });
                    // Use utils/getLatLngs.js
                    const parcelBoundaries = getLatLngs(parcel);
                    digitizePolygon(parcelBoundaries[0], map);
                }

                // Specific to tdCreateMode
                if (tdCreateMode) {
                    console.log("TD Mode");
                    map.pm.removeControls();
                }

                // Specific to mapCreateMode
                if (mapCreateMode) {
                    map.pm.addControls({
                        drawPolygon: true,
                        editMode: true,
                        cutPolygon: true,
                        removalMode: true,
                    });
                }
            }
            else if (enableEditTools) {
                // Remove previous

                if (drawnLayerRef.current) {
                    map.removeLayer(drawnLayerRef.current);
                    drawnLayerRef.current = null;
                    setDrawnLayer(null); // TODO Revisit
                }

                if (drawnLayerGroupRef.current) {
                    map.removeLayer(drawnLayerGroupRef.current)
                    drawnLayerGroupRef.current = null;
                }

                if (taxParcel) {
                    startEditingMode(taxParcel);
                }

                // Turn off all the layers events to avoid interfering with the drawing events
                offAllLayers();

                // Specific to partitionEditMode
                if (partitionEditMode) {
                    map.pm.Toolbar.buttons.drawPolyline._button.title = 'Cutting Line';

                    map.pm.addControls({
                        drawPolyline: true,
                        editMode: false,
                        cutPolygon: false,
                        removalMode: false,
                    });

                    partitionDrawingLayer();
                }
            }
            else {
                map.pm.removeControls();
                if (drawnLayerRef.current) {
                    map.removeLayer(drawnLayerRef.current);
                    drawnLayerRef.current = null;
                    setDrawnLayer(null);
                }
                onAllLayers();
                map.off('pm:drawstart');
                map.off('pm:create');
            }
        }
        // eslint-disable-next-line
    }, [parcelCreateMode, tdCreateMode, mapCreateMode, partitionEditMode, enableEditTools, userInitiatedChange]);

    useEffect(() => {
        if (isCommitted) {
            console.log("SUCCESS");
            setEnableCreateTools(false);
            setEnableEditTools(false);
            setParcelCreateMode(false);
            setTdCreateMode(false);
            setMapCreateMode(false);
            setPartitionEditMode(false);
            setDrawnLayer(null);
            setIsCommitted(false);
            setIsPartitioned(false);
            cleanMapFromScan();
        }
        // eslint-disable-next-line
    }, [isCommitted]);

    const cleanMapFromScan = () => {
        const map = mapRef.current;
        // Remove the previous scannedLayer if it exists
        if (scannedLayerRef.current) {
            map.removeLayer(scannedLayerRef.current);
            scannedLayerRef.current = null;
        }

        // Remove previous markers if they exist
        if (markerRefs.current) {
            markerRefs.current.forEach(marker => {
                map.removeLayer(marker);
            });
            markerRefs.current = []; // Clear the marker references
        }
    };

    //Rendering uploaded image
    useEffect(() => {
        if (isUploaded) {
            const map = mapRef.current;
            //const layerControl = layerControlRef.current;

            // Remove the previous scannedLayer if it exists
            if (scannedLayerRef.current) {
                map.removeLayer(scannedLayerRef.current);
                scannedLayerRef.current = null;
            }

            // Remove previous markers if they exist
            if (markerRefs.current) {
                markerRefs.current.forEach(marker => {
                    map.removeLayer(marker);
                });
                markerRefs.current = []; // Clear the marker references
            }

            // Retrieve the Base64 image from local storage
            const base64Image = localStorage.getItem('uploadedImage');
            if (!base64Image) {
                alert('No image found in local storage.');
            }

            // Convert Base64 string to Data URL (if not already in Data URL format)
            const dataUrl = base64Image.startsWith('data:image/jpeg;base64,')
                ? base64Image
                : 'data:image/jpeg;base64,' + base64Image.split(',')[1];

            // Create an HTMLImageElement and set the Data URL as the source
            const img = new Image();
            img.src = dataUrl;

            // Three corner points
            const parcelBounds = parseBBox(JSON.stringify(parcel.bbox)).bounds;

            const top = parcelBounds[1][0];
            const bottom = parcelBounds[0][0];
            const left = parcelBounds[0][1];
            const right = parcelBounds[1][1];

            const topleft = L.latLng(top, left);
            const topright = L.latLng(top, right);
            const bottomleft = L.latLng(bottom, left);

            // Use the image element in the Leaflet image overlay
            const scannedLayer = L.imageOverlay.rotated(img, topleft, topright, bottomleft, {
                opacity: 0.4,
                pmIgnore: true,
                interactive: true,
                attribution: "&copy; Survey Plan from LRA/LMB. All rights reserved."
            });

            // Define your custom icon
            const customIcon = new L.Icon({
                iconUrl: '/images/location.png', // Path to the icon image in the public folder
                iconSize: [32, 32], // Size of the icon [width, height]
                iconAnchor: [16, 4], // Point of the icon which will correspond to marker's location
                popupAnchor: [0, 0] // Point from which the popup should open relative to the iconAnchor
            });

            // Function to reposition the image based on marker positions
            const repositionImage = () => {
                scannedLayer.reposition(markerTopLeft.getLatLng(), markerTopRight.getLatLng(), markerBottomLeft.getLatLng());
            };

            // Add markers to the map with custom icon
            const markerTopLeft = L.marker(topleft, { draggable: true, icon: customIcon, pmIgnore: true }).addTo(map);
            const markerTopRight = L.marker(topright, { draggable: true, icon: customIcon, pmIgnore: true }).addTo(map);
            const markerBottomLeft = L.marker(bottomleft, { draggable: true, icon: customIcon, pmIgnore: true }).addTo(map);

            // Attach the dragend event listener to each marker
            markerTopLeft.on('drag dragend', repositionImage);
            markerTopRight.on('drag dragend', repositionImage);
            markerBottomLeft.on('drag dragend', repositionImage);

            // Store the layer and markers to their respective refs
            scannedLayerRef.current = scannedLayer;
            markerRefs.current = [markerTopLeft, markerTopRight, markerBottomLeft];

            scannedLayer.setZIndex(500);
            scannedLayer.addTo(map);
        }
        // eslint-disable-next-line
    }, [isUploaded]);

    return (
        <Card sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column' }}>
            <CardContent sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', p: 0 }}>
                <Typography variant="h6" fontWeight="bold" p={2}>
                    Tax Mapper
                </Typography>
                <div id="map" style={{ flex: '1 1 auto', minHeight: '640px' }}></div>
            </CardContent>
        </Card>
    );
}

export default MapViewer;
