import React from 'react';

// Helper function to split message by newline and map to JSX
const renderMessageWithLineBreaks = (message) => {
    return message.split('\n').map((line, index) => (
        <span key={index}>
            {line}
            <br />
        </span>
    ));
};

export default renderMessageWithLineBreaks;
