import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, CircularProgress, Fade } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import ATMSSHeader from '../../components/material_ui/common/ATMSSHeader';
import ATMSSFooter from '../../components/material_ui/common/ATMSSFooter';
import MapViewer from '../../components/material_ui/parcel_manager/MapViewer';
import ParcelFabric from '../../components/material_ui/parcel_manager/ParcelFabric';
import GeoTools from '../../components/material_ui/parcel_manager/GeoTools';

const ParcelManagerPage = () => {
    const theme = useTheme();

    return (
        <Box sx={{ width: '100%', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
            <Box sx={{ flex: '0 1 auto' }}>
                <ATMSSHeader />
            </Box>
            <Box sx={{ flex: '1 1 auto', display: 'flex', p: 2 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={2}>
                        <Paper elevation={3} sx={{ height: '100%', p: 2, backgroundColor: theme.palette.background.paper }}>
                            <GeoTools />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper elevation={3} sx={{ height: '100%', p: 2, backgroundColor: theme.palette.background.paper }}>
                            <MapViewer />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={2}>
                        <Paper elevation={3} sx={{ height: '100%', p: 2, backgroundColor: theme.palette.background.paper }}>
                            <ParcelFabric />
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ flex: '0 1 auto' }}>
                <ATMSSFooter />
            </Box>
        </Box>
    );
};

export default ParcelManagerPage;
