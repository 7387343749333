import React, { useContext } from 'react';

import { Box, Typography, IconButton, Tooltip } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useNavigate } from 'react-router-dom';

import LogoutIcon from '@mui/icons-material/Logout'; // Import Logout icon
import { AuthContext } from '../../../context/AuthContext'; // Import AuthContext

const ATMSSHeader = () => {
    const navigate = useNavigate();
    const theme = useTheme();
    const { logout } = useContext(AuthContext);

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleLogoutClick = () => {
        logout(); // Call logout function from context
        navigate('/login'); // Redirect to the login page
    };

    return (
        <Box
            component="header"
            sx={{
                width: '100%', // Ensure the header spans the full width of the screen
                backgroundColor: '#062A67', // Use the primary color from the theme
                color: theme.palette.primary.contrastText, // Use the contrast text color from the theme
                py: 2,
                borderBottom: `1px solid ${theme.palette.divider}`, // Use theme divider color
                px: 2, // Apply padding on the x-axis
                [theme.breakpoints.up('sm')]: {
                    px: 4, // Increase padding on larger screens
                },
            }}
        >
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ flexWrap: 'wrap' }}
            >
                {/* Left-aligned Logo */}
                <Tooltip title="Go to Home" arrow>
                    <IconButton
                        onClick={handleLogoClick}
                        sx={{
                            padding: 0,
                            background: 'none',
                            boxShadow: 'none',
                        }}
                    >
                        <img
                            src="/images/SSSI_s.png"
                            alt="SSSI Logo"
                            style={{ height: '50px', width: 'auto', marginRight: '10px' }}
                        />
                    </IconButton>
                </Tooltip>

                {/* Centered Title */}
                <Typography
                    variant="h6"
                    component="h1"
                    fontWeight="bold"
                    fontFamily={theme.typography.fontFamily} // Use the custom font family from the theme
                    color="inherit" // Inherit the color set on the parent Box (contrastText)
                    textAlign="center"
                    flexGrow={1}
                    m={0}
                >
                    Automated Tax Mapping and Sectioning Systems (ATMSS)
                </Typography>

                {/* Right-aligned Logout Button */}
                <Tooltip title="Logout" arrow>
                    <IconButton
                        onClick={handleLogoutClick} // Add onClick handler for logout
                        sx={{
                            color: 'inherit', // Use the contrast text color from the theme
                            ml: 'auto', // Align the logout button to the right
                        }}
                    >
                        <LogoutIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
};

export default ATMSSHeader;
