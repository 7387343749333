import { createTheme } from '@mui/material/styles';

// Light Theme
export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#062A67',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
    text: {
      primary: '#333333',
      secondary: '#666666',
    },
  },
  typography: {
    fontFamily: 'GothamMedium, sans-serif',
    h6: {
      fontWeight: 'bold',
    },
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          filter: 'brightness(0.5)', // Example style for light theme
        },
      },
    },
  },
});

// Dark Theme
export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#0A8DF1',
    },
    secondary: {
      main: '#f48fb1',
    },
    background: {
      default: '#121212',
      paper: '#1e1e1e',
    },
    text: {
      primary: '#ffffff',
      secondary: '#bbbbbb',
    },
  },
  typography: {
    fontFamily: 'GothamMedium, sans-serif',
    h6: {
      fontWeight: 'bold',
    },
  },
  components: {
    MuiIcon: {
      styleOverrides: {
        root: {
          filter: 'brightness(1.5) invert(1)', // Example style for dark theme
        },
      },
    },
  },
});
